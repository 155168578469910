import { SWITCH_LANGUAGE, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH, URL } from "constants/ActionTypes";
import {
  HORIZONTAL_MENU_POSITION,
  LAYOUT_TYPE,
  NAV_STYLE,
  THEME_COLOR,
  THEME_TYPE,
  VERTICAL_NAVIGATION,
  SHIFT_DATA,
  FULL_NAME, SET_TIME, TITLE
} from "../../constants/ThemeSetting";
export const FILTER_DATA = 'FILTER_DATA';


export function toggleCollapsedSideNav(navCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, navCollapsed };
}

export function updateWindowWidth(width) {
  return (dispatch) => {
    dispatch({ type: WINDOW_WIDTH, width });
  }

}

export function setThemeType(themeType) {
  return (dispatch) => {
    dispatch({ type: THEME_TYPE, themeType });
  }
}

export function setThemeColor(themeColor) {
  console.log("ms", themeColor)
  return (dispatch) => {
    dispatch({ type: THEME_COLOR, themeColor });
  }
}

export function onNavStyleChange(navStyle) {
  return (dispatch) => {
    dispatch({ type: NAV_STYLE, navStyle });
  }
}

export function onHorizontalNavPositionChange(horizontalNavPosition) {
  return { type: HORIZONTAL_MENU_POSITION, horizontalNavPosition };
}

export function onVerticalNavStyleChange(verticalNavStyle) {
  return {type: VERTICAL_NAVIGATION, verticalNavStyle};
}

export function setLayoutTypeChange(layoutType) {
  return {type: LAYOUT_TYPE, layoutType};
}

export function onLayoutTypeChange(layoutType) {
  return (dispatch) => {
    dispatch({ type: LAYOUT_TYPE, layoutType });
  }
}

export function switchLanguage(locale) {
  return (dispatch) => {
    dispatch({
      type: SWITCH_LANGUAGE,
      payload: locale
    });
  }
}

export function setfilteredData(filteredData) {
  return { type: FILTER_DATA, filteredData };
}
export function setProfileTopCorner(setProfile) {
  return { type: SHIFT_DATA, setProfile };
}
export function setFullName(name) {
  return { type: FULL_NAME, name };
}
export function setTitle(title) {
  return { type: TITLE, title };
}
export function setURL(url) {
  return { type: URL, url };
}
export function setTime(setTime) {
  return { type: SET_TIME, setTime };
}

