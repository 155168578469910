import React, { PureComponent } from 'react';
import {
  Button,
  Form,
  Input, message, Spin, Tooltip,
} from "antd";
import { withRouter } from "react-router-dom";
import * as urlConfig from '../../../../constants/URLConstant';
// import loginLogo from '../../../../assets/images/logo/logo.png';
import AuthService from '../../../../service/AuthService';
import '../Auth.css'
import { LockOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const containDigits = "(?=.*[0-9])";
const dataDigits = "0-9";
const containLetters = "A-Za-z";
const containUpperCase = "(?=.*[A-Z])";
const dataUpperCase = "A-Z";
const containLowerCase = "(?=.*[a-z])";
const dataLowerCase = "a-z";
const containSymbol = "(?=.*[a-z])"
const dataSymbol = "a-z"
const dataSpecialCharacter = "!@#$%^&*)(+=._-"
const containSpecialCharacter = "(?=.*[!@#$%^&*)(+=._-])"

var minimumLenght = 10
var maximunLengh = 16
var data = ''
var array = ''
var tooltip = ''
var tooltipData = []
class SecondStep extends PureComponent {
  state = {
    loadingData: false,
    encrypted_data: "",
    passWord: "",
    showConfirmPassword: false,
    spinLoading: false,
    newRegEx: '',
    firstPassword: '',
    allTooltipData: [],
    userdata: {
      digits: '',
      id: '',
      is_active: '',
      letters: '',
      lowercase: '',
      maximum_length: '',
      minimum_length: '',
      organization_id: '',
      special_characters: '',
      symbols: '',
      uppercase: ''
    },
    digits: false,
    letters: false,
    lowercase: false,
    maximum_length: false,
    minimum_length: false,
    organization_id: false,
    special_characters: false,
    symbols: false,
    uppercase: false,
    lowercaseData: 'Should be one Lowercase character : a-z',
    logo: null
  };

  componentWillMount() {
    // var data = this.props.location.search;
    // let params = new URLSearchParams(data);
    // let value = params.get('value');
    // if (value && value !== null) {
    //   this.setState({ encrypted_data: value });
    // }
  }

  compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== this.state.password) {
      callback('Two passwords that you entered are inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    // const { form } = this.props;
    // if (value && this.state.confirmDirty) {
    //   form.validateFields(['confirm'], { force: true });
    // }
    // if (this.state.newRegEx.test(value)) {
    //   this.setState({ showConfirmPassword: true })
    // } else if (!this.state.newRegEx.test(value)) {
    //   this.setState({ showConfirmPassword: false })
    // }
    // callback();
    return true
  };

  handleSubmit = values => {
    this.setState({ error: "", loading: true });
    this.resetPassword(values)

  };

  resetPassword = async (formdata) => {
    formdata = {
      ...formdata,
      encrypted_data: this.state.encrypted_data
    }
    const response = await AuthService.ResetPassword(formdata);
    if (response.status === urlConfig.SUCCESS_CODE) {
      this.setState({ loading: false });
      const { from } = this.props.location.state || { from: { pathname: `/login` } };
      message.success(response.message)
      this.props.history.push(from);
    }
    else {
      this.setState({ loading: false });
      message.error(response.message);

    }
  }

  resetPasswordData = () => {
    this.props.history.push(`/login`);
  }

  componentDidMount = async () => {
    this.setState({ spinLoading: true })
    var data = this.props.location.search;
    let params = new URLSearchParams(data);
    let value = data;
    if (value && value !== null) {
      this.setState({ encrypted_data: value });
      // this.getPasswordPolicy(value)
    } else {
      this.setState({
        userdata: null,
        spinLoading: false
      })
    }
    this.setState({
      spinLoading: false
    })
  }
  setRegEx = () => {

    if (this.state.userdata !== null) {
      const { digits,
        letters,
        lowercase,
        maximum_length,
        minimum_length,
        special_characters,
        symbols,
        uppercase } = this.state.userdata
      minimumLenght = minimum_length ? minimum_length : 6;
      maximunLengh = maximum_length ? maximum_length : 16;
      if (digits) {
        data = containDigits
        array = dataDigits
        tooltip = tooltipData.push("Should be one Number : 0-9")
        this.setState({ digits: true })
      }
      if (letters) {
        if (uppercase && lowercase) {
          data = data + containUpperCase + containLowerCase
          array = array + containLetters
          tooltip = tooltipData.push("Should be one Uppercase character : A-Z")
          tooltip = tooltipData.push('Should be one Lowercase character : a-z')
          this.setState({ uppercase: true, lowercase: true })
        }
        else if (uppercase) {
          data = data + containUpperCase
          array = array + dataUpperCase
          tooltip = tooltipData.push("Should be one Uppercase character : A-Z")
          this.setState({ uppercase: true })
        } else if (lowercase) {
          data = data + containLowerCase
          array = array + dataLowerCase
          tooltip = tooltipData.push(this.state.lowercaseData)
          this.setState({ lowercase: true })
        }

      }
      if (symbols) {
        data = data + containSymbol
        array = array + dataSymbol
        tooltip = tooltipData.push('Symbol : !,@,#,$,%,^,*,(,)')
        this.setState({ symbols: true })
      }
      if (special_characters) {
        data = data + containSpecialCharacter
        array = array + dataSpecialCharacter
        tooltip = tooltipData.push('Should be one Special character: !@#$%^&*)(+=._-')
        this.setState({ special_characters: true })
      }
      if (!digits && !letters && !symbols && !special_characters) {
        data = data + containUpperCase + containLowerCase + containDigits
        array = containLetters + dataDigits
        tooltip = tooltipData.push("Should be one Uppercase character : A-Z")
        tooltip = tooltipData.push('Should be one Lowercase character : a-z')
        tooltip = tooltipData.push("Should be one Number : 0-9")


        this.setState({ uppercase: true, lowercase: true, digits: true })

      }
      tooltip = tooltipData.push(`${"Minimum password length " + minimumLenght}`)
      tooltip = tooltipData.push(`${"Maximum password length " + maximunLengh}`)
      // tooltipData = tooltipData && tooltipData.map(data => data)
      tooltipData = <span>Password must:
      <ul>
          {tooltipData && tooltipData.map(data => <li>{data}</li>)}
          {/* <li>Be at least {minimumLenght} characters</li>
          <li>Maximum {maximunLengh} characters</li> */}
        </ul>
      </span>
    } else {
      minimumLenght = 6;
      maximunLengh = 16;
      data = data + containUpperCase + containLowerCase + containDigits
      array = containLetters + dataDigits
      tooltip = tooltipData.push("Should be one Uppercase character : A-Z")
      tooltip = tooltipData.push('Should be one Lowercase character : a-z')
      tooltip = tooltipData.push(`${"Minimum password length " + minimumLenght}`)
      tooltip = tooltipData.push(`${"Maximum password length " + maximunLengh}`)
      tooltipData = <span>Password must:
      <ul>
          {tooltipData && tooltipData.map(data => <li>{data}</li>)}
          {/* <li>Be at least {minimumLenght} characters</li>
          <li>Maximum {maximunLengh} characters</li> */}
        </ul>
      </span>
      this.setState({ uppercase: true, lowercase: true, digits: true })

    }
    const matchRegEx = new RegExp("" + data + "[" + array + "]{" + minimumLenght + "," + maximunLengh + "}$", 'g')
    this.setState({ newRegEx: matchRegEx, allTooltipData: tooltipData })
    console.log(tooltip)
  }
  passwordChange = (value) => {

    this.setState({ password: value.target.value })


  }

  confirmPasswordChange = (e) => {
    this.setState({ confirmPassword: e.target.value, })
  }

  render() {
    const { passWord, encrypted_data, showConfirmPassword } = this.state;

    return (
      <Form onFinish={this.handleSubmit} layout="vertical" className="gx-login-form gx-form-row0">

        <Spin tip="Loading..." spinning={this.state.spinLoading}>
          <Form.Item style={{ display: 'none' }} name='encrypted_data' rules={[
            {
              required: false
            }
          ]}>

            <Input name='encrypted_data' defaultValue={encrypted_data} />
          </Form.Item>

          <Form.Item label="New password" name='password' rules={[

            {
              required: true,
              message: 'Please input your new password!',
            },
          ]}>
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              name='password'
              onChange={this.passwordChange}
            // maxLength={maximunLengh}
            />
          </Form.Item>
          {showConfirmPassword ?
            <Form.Item label="Confirm Password" name='confirm' rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              {
                validator: this.compareToFirstPassword,
              },
            ]}>

              <Input.Password
                name='confirm'
                // onBlur={this.handleConfirmBlur}
                onChange={this.confirmPasswordChange}
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                maxLength={maximunLengh}
              />
            </Form.Item> : <Form.Item label="Confirm Password" name='confirm' rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              {
                validator: this.compareToFirstPassword,
              },
            ]} >
              <Input.Password
                // disabled
                name='confirm'
                // onBlur={this.handleConfirmBlur}
                onChange={this.confirmPasswordChange}
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              />
            </Form.Item>}

          <FormItem className="gx-text-center">
            <Button
              type="primary"
              htmlType="submit"
              loading={this.state.loading}
            >
              Submit
            </Button>
            <Button
              type="reset"
              htmlType="reset"
              onClick={this.resetPasswordData}
            >
              Cancel
            </Button>
          </FormItem>
        </Spin>
      </Form >
    );
  }
}

export default withRouter(SecondStep);
