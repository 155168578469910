import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";

const User = ({ match }) => (
  <Switch>


    <Route path={`${match.url}/score`} exact component={asyncComponent(() => import('./score'))} />
    <Route path={`${match.url}/userdetails`} exact component={asyncComponent(() => import('./ScoreView/UserDetails'))} />
    <Route path={`${match.url}/viewlevel`} exact component={asyncComponent(() => import('./ScoreView/ViewLevel'))} />
    <Route path={`${match.url}/chapterwise`} exact component={asyncComponent(() => import('./ScoreView/ChepterWise'))} />
    <Route path={`${match.url}/userdetails/absolute`} exact component={asyncComponent(() => import('./scoreviewAbsolute/UserDetails'))} />
    <Route path={`${match.url}/viewlevel/absolute`} exact component={asyncComponent(() => import('./scoreviewAbsolute/ViewLevel'))} />
    <Route path={`${match.url}/chapterwise/absolute`} exact component={asyncComponent(() => import('./scoreviewAbsolute/ChepterWise'))} />
    <Route path={`${match.url}/resetpassword`} exact component={asyncComponent(() => import('./ResetAdminPassword'))} />
  </Switch>
);

export default User;
