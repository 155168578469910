import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { connect, useSelector } from "react-redux";
import { DeploymentUnitOutlined, ProjectOutlined } from "@ant-design/icons";
import * as urlConfig from '../../constants/URLConstant';
import UserContext from "../../contexts/UserContext";
import Auxiliary from "../../util/Auxiliary";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;


const SidebarContent = (props, { sidebarCollapsed }) => {

  let { navStyle, themeType } = useSelector(({ settings }) => settings);
  let { pathname } = useSelector(({ common }) => common);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };


  const removeFirstChar = str => str.substring(1);

  const findSideMenuKeys = key => props.pathname.includes(key)
    ? removeFirstChar(props.pathname)
    : removeFirstChar(key);

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  return (
    <>
      <UserContext.Consumer>
        {({ userDetails }) => (
          <Auxiliary>
            <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={(data) => props.setSidebarCollapsed(data)} />
            <div className="gx-sidebar-content">
              <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
                <UserProfile userDetails={{ userDetails }} />
                {/* <AppsNavigation /> */}
              </div>
              <CustomScrollbars className="gx-layout-sider-scrollbar">
                <Menu
                  defaultOpenKeys={[defaultOpenKeys]}
                  selectedKeys={[selectedKeys]}
                  theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                  mode="inline">
                  <MenuItemGroup key="main" title={
                    <span style={{ fontWeight: '800' }}>
                      <ProjectOutlined style={{ marginRight: 16 }} /> <IntlMessages id="sidebar.main" />
                    </span>
                  }>

                    <Menu.Item key={findSideMenuKeys(urlConfig.DASHBOARD_ROUTE)}>
                      <Link to={`/dashboard/crm`}><i className="icon icon-widgets" />
                        <span><IntlMessages id="sidebar.dashboard" /></span></Link>
                    </Menu.Item>


                  </MenuItemGroup>




                  {(userDetails && userDetails.role.includes(urlConfig.ADMINISTRATOR)) && (
                    <MenuItemGroup key="main" title={
                      <span style={{ fontWeight: '800' }}>
                        <DeploymentUnitOutlined style={{ marginRight: 16 }} /> <IntlMessages id="sidebar.superadmin" />
                      </span>}>

                      <Menu.Item key={findSideMenuKeys(urlConfig.SUPER_ADMIN_COMPANY_LIST2)}>
                        <Link to={urlConfig.SUPER_ADMIN_COMPANY_LIST}> <i className="icon icon-files" />
                          <span> Company </span></Link>
                      </Menu.Item>

                      <Menu.Item key={findSideMenuKeys(urlConfig.SUPER_ADMIN_BATCH_LIST)}>
                        <Link to={urlConfig.SUPER_ADMIN_BATCH_LIST}><i className="icon icon-navigation" />
                          <span> Batch </span></Link>
                      </Menu.Item>

                      <SubMenu key="license"
                        title={<span>   <i className="icon icon-draft" />
                          <span>License</span></span>}>
                        <Menu.Item key={findSideMenuKeys(urlConfig.SUPER_ADMIN_LICENCE_SUMMARY_LIST)}>
                          <Link to={urlConfig.SUPER_ADMIN_LICENCE_SUMMARY_LIST}><i className="icon icon-ellipse-v" />
                            <span> Summary </span></Link>
                        </Menu.Item>
                        <Menu.Item key={findSideMenuKeys(urlConfig.SUPER_ADMIN_LICENCE_LIST)}>
                          <Link to={urlConfig.SUPER_ADMIN_LICENCE_LIST}><i className="icon icon-ellipse-v" />
                            <span> Details </span></Link>
                        </Menu.Item>

                      </SubMenu>
                      <Menu.Item key={findSideMenuKeys(urlConfig.SUPER_ADMIN_INDUSTRY_LIST)}>
                        <Link to={urlConfig.SUPER_ADMIN_INDUSTRY_LIST}><i className="icon icon-navigation" />
                          <span> Industry </span></Link>
                      </Menu.Item>
                      <Menu.Item key={findSideMenuKeys(urlConfig.SUPER_ADMIN_USER_LIST)}>
                        <Link to={urlConfig.SUPER_ADMIN_USER_LIST}><i className="icon icon-chat" />
                          <span> User </span></Link>
                      </Menu.Item>
                      <Menu.Item key={findSideMenuKeys(urlConfig.SUPER_ADMIN_SCORE_LIST)}>
                        <Link to={urlConfig.SUPER_ADMIN_SCORE_LIST}><i className="icon icon-cards-list-view" />
                          <span> Scores </span></Link>
                      </Menu.Item>
                      <Menu.Item key={findSideMenuKeys(urlConfig.SUPER_ADMIN_LEVEL_LIST)}>
                        <Link to={urlConfig.SUPER_ADMIN_LEVEL_LIST}><i className="icon icon-data-entry" />
                          <span> Level data </span></Link>
                      </Menu.Item>
                      <Menu.Item key={findSideMenuKeys(urlConfig.SUPER_ADMIN_RESET_PASSWORD)}>
                        <Link to={urlConfig.SUPER_ADMIN_RESET_PASSWORD}> <i className="icon icon-files" />
                          <span> Reset Password </span></Link>
                      </Menu.Item>


                    </MenuItemGroup>
                  )}
                  {(userDetails && userDetails.role.includes(urlConfig.COMPANYADMIN)) && (
                    <MenuItemGroup key="/companyadmin" title={
                      <span style={{ fontWeight: '800' }}>
                        <DeploymentUnitOutlined style={{ marginRight: 16 }} /> <IntlMessages id="Company admin options" />
                      </span>}>

                      <Menu.Item key={findSideMenuKeys(urlConfig.COMPANY_ADMIN_BATCH_LIST)}>
                        <Link to={urlConfig.COMPANY_ADMIN_BATCH_LIST}><i className="icon icon-navigation" />
                          <span> Batch </span></Link>
                      </Menu.Item>

                      <SubMenu key="license"
                        title={<span>   <i className="icon icon-draft" />
                          <span>License</span></span>}>
                        <Menu.Item key={findSideMenuKeys(urlConfig.COMPANY_ADMIN_LICENCE_SUMMARY_LIST)}>
                          <Link to={urlConfig.COMPANY_ADMIN_LICENCE_SUMMARY_LIST}><i className="icon icon-ellipse-v" />
                            <span> Summary </span></Link>
                        </Menu.Item>
                        <Menu.Item key={findSideMenuKeys(urlConfig.COMPANY_ADMIN_LICENCE_LIST)}>
                          <Link to={urlConfig.COMPANY_ADMIN_LICENCE_LIST}><i className="icon icon-ellipse-v" />
                            <span> Details </span></Link>
                        </Menu.Item>


                      </SubMenu>
                      <Menu.Item key={findSideMenuKeys(urlConfig.COMPANY_ADMIN_USER_LIST)}>
                        <Link to={urlConfig.COMPANY_ADMIN_USER_LIST}><i className="icon icon-chat" />
                          <span> User </span></Link>
                      </Menu.Item>
                      <Menu.Item key={findSideMenuKeys(urlConfig.COMPANY_ADMIN_SCORE_LIST)}>
                        <Link to={urlConfig.COMPANY_ADMIN_SCORE_LIST}><i className="icon icon-cards-list-view" />
                          <span> Scores </span></Link>
                      </Menu.Item>
                      <Menu.Item key={findSideMenuKeys(urlConfig.COMPANY_ADMIN_RESET_PASSWORD)}>
                        <Link to={urlConfig.COMPANY_ADMIN_RESET_PASSWORD}> <i className="icon icon-files" />
                          <span> Reset Password </span></Link>
                      </Menu.Item>
                    </MenuItemGroup>
                  )}

                  {(userDetails && userDetails.role.includes(urlConfig.USER)) && (
                    <MenuItemGroup key="/user" title={
                      <span style={{ fontWeight: '800' }}>
                        <DeploymentUnitOutlined style={{ marginRight: 16 }} /> <IntlMessages id="User options" />
                      </span>}>


                      <Menu.Item key={findSideMenuKeys(urlConfig.USER_DETAILS)}>
                        <Link to={urlConfig.USER_DETAILS}><i className="icon icon-cards-list-view" />
                          <span> Scores </span></Link>
                      </Menu.Item>
                      <Menu.Item key={findSideMenuKeys(urlConfig.USER_RESET_PASSWORD)}>
                        <Link to={urlConfig.USER_RESET_PASSWORD}> <i className="icon icon-files" />
                          <span> Reset Password </span></Link>
                      </Menu.Item>
                    </MenuItemGroup>
                  )}

                </Menu>
              </CustomScrollbars>
            </div>
          </Auxiliary>
        )
        }
      </UserContext.Consumer>
    </>
  );
};

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings }) => {
  const { navStyle, verticalNavStyle, themeType, locale, pathname } = settings;
  return { navStyle, verticalNavStyle, themeType, locale, pathname }
};
export default connect(mapStateToProps)(SidebarContent);

