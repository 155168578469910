/**
 * This is base URL for api call
 */


export var BASE_URL = "";

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    BASE_URL = "http://localhost:6789/";

} else {
    BASE_URL = "https://game.leadupp.co/"; // Live
    BASE_URL = "https://test.game.leadupp.co/";// dev
}

//BASE_URL = "https://game.leadupp.co/"; // Live
BASE_URL = "https://test.game.leadupp.co/";// dev
console.log(BASE_URL);
