import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from './dashboard';
import Main from "./main/index";
import User from './User/index'
import UserContext from '../contexts/UserContext';
import * as urlConfig from '../constants/URLConstant';
import CompanyAdmin from './companyadmin'
import {
  ACCESS_DENIED,
} from '../constants/URLConstant';
import './index.css'

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <UserContext.Consumer>
      {
        user => (

          <Switch>
            {
              user.userDetails ? <Route path={`${match.url}dashboard`} component={Dashboard} /> : null
            }

            {
              user.userDetails && user.userDetails.role.includes(urlConfig.ADMINISTRATOR)
                ? <Route path={`${match.url}main`} component={Main} /> : null
            }
            {
              user.userDetails && user.userDetails.role.includes(urlConfig.COMPANYADMIN)
                ? <Route path={`${match.url}companyadmin`} component={CompanyAdmin} /> : null
            }
            {
              user.userDetails && user.userDetails.role.includes(urlConfig.USER)
                ? <Route path={`${match.url}user`} component={User} /> : null
            }
            <Redirect to={ACCESS_DENIED} />
          </Switch>
        )
      }
    </UserContext.Consumer>
  </div>
);

export default App;
