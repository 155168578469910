import React from "react";
import { Button, message, Form, Modal, } from "antd";
import { connect, } from "react-redux";
import { Link, } from "react-router-dom";
import UserContext from "../../../contexts/UserContext";
import AuthService from "../../../service/AuthService.js";
import Session from 'store2';
import * as urlConfig from '../../../constants/URLConstant';
import IntlMessages from "util/IntlMessages";
import InputPrefix from "../InputControl/InputPerefix/InputPrefix";
import { clearCredentials } from "./AuthReducer.js";
import {
  userSignInSuccess
} from "appRedux/actions/Auth";
import './Signin.css'

var memoryStorage = require('memorystorage')
var key = []

function isEmpty(o) {
  for (var i in o) {
    return false;
  }
  return true;
};


class SignIn extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      loading: false,
      isShowPopup: false,
      loder: false
    }
  }

  componentDidMount() {
    this.testLogin()
    this.fetchData()
  }

  testLogin = async () => {
    this.setState({ loading: true })
    var data = this.props.location.search;
    if (data && data.length > 0) {
      data = data.substring(1);
      if (data && data.length > 0) {
        let body = {
          data: data
        }
        const response = await AuthService.GameLogin(body);
        if (response.status === urlConfig.SUCCESS_CODE) {
          Session.session('auditLogging', new Date().toLocaleString());
          Session.session("userAccessToken", data);
          this.props.userSignInSuccess(data)
          sessionStorage.removeItem("session_token")
          this.props.clearAuth();
          this.context.login();
          setTimeout(() => {
            this.props.history.push('/dashboard/crm');
          }, 2000);
        }
      }
    }
    this.setState({ loading: false })
  }

  fetchData = async () => {
    memoryStorage = {};
    if (this.isEmpty(memoryStorage)) {
      // Ask other tabs for memoryStorage
      localStorage.setItem('getSessionStorage', Date.now());
    };

    window.addEventListener('storage', function (event) {

      //console.log('storage event', event);

      if (event.key === 'getSessionStorage') {
        // Some tab asked for the memoryStorage -> send it

        localStorage.setItem('sessionStorage', JSON.stringify(memoryStorage));
        localStorage.removeItem('sessionStorage');

      } else if (event.key === 'sessionStorage' && isEmpty(memoryStorage)) {
        // sessionStorage is empty -> fill it

        var data = JSON.parse(event.newValue);

        for (key in data) {
          memoryStorage[key] = data[key];
        }

        // showSessionStorage(memoryStorage);
      }
    });

    window.onbeforeunload = function () {
      //sessionStorage.clear();
    };
  }

  isEmpty = (o) => {
    for (var i in o) {
      return false;
    }
    return true;
  };

  onFinish = value => {
    this.setState({ loading: true })
    value.email = value.email.toLowerCase()
    const formData = {
      ...value,
    };
    this.checkLogin(formData);
  };

  checkLogin = async (formData) => {
    const response = await AuthService.getEulaStatus(formData);
    if (response.status === urlConfig.SUCCESS_CODE) {
      if (response.data && response.data.eula_acceptance === true) {
        this.handleSubmitEula(formData)
      } else {
        this.setState({ isShowPopup: true, formData: formData, loading: false })
      }
    } else {
      this.setState({ loading: false })
      message.error(response.message);
    }

  }

  resetPassword = () => {
    setTimeout(() => {
      this.props.history.push(`/reset-password`);
    }, 2000);

  }


  handleSubmitEula = async (formData) => {
    if (!formData) {
      formData = this.state.formData
    }
    this.setState({ loading: true, loder: true })
    const response = await AuthService.Login(formData);
    if (response.status === urlConfig.SUCCESS_CODE) {
      Session.session('auditLogging', new Date().toLocaleString());
      Session.session("userAccessToken", response.token);
      const responseStatus = await AuthService.saveEulaStatus();
      if (responseStatus.status === urlConfig.SUCCESS_CODE) {
        Session.session('auditLogging', new Date().toLocaleString());
        Session.session("userAccessToken", response.token);
        this.props.userSignInSuccess(response.token)
        Session.session("government_id", 'Available');
        sessionStorage.removeItem("session_token")
        this.props.clearAuth();
        this.context.login();
        setTimeout(() => {
          this.props.history.push('/dashboard/crm');
        }, 2000);
      } else {
        message.error(response.message);
      }


    } else {
      message.error(response.message);
    }
    this.setState({ loading: false, loder: false })
  }

  createMarkup = () => {
    return {
      __html: `<html>

    <head>
        <meta http-equiv=Content-Type content="text/html; charset=utf-8">
    
        <style>
            .titletext {
                font-size: 8pt;
            }
            
            p {
                font-size: 8pt;
            }
            
            body {
                font-family: "Calibri", serif;
            }
            
            p.MsoNormal,
            li.MsoNormal,
            div.MsoNormal {
                margin: 0in;
                font-family: "Calibri", serif;
            }
            
            p.MsoCommentText,
            li.MsoCommentText,
            div.MsoCommentText {
                mso-style-link: "Comment Text Char";
                margin: 0in;
                font-family: "Calibri", serif;
            }
            
            a:link,
            span.MsoHyperlink {
                color: #0563C1;
                text-decoration: underline;
            }
            
            p.MsoListParagraph,
            li.MsoListParagraph,
            div.MsoListParagraph {
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 0in;
                margin-left: .5in;
                font-family: "Calibri", serif;
            }
            
            p.MsoListParagraphCxSpFirst,
            li.MsoListParagraphCxSpFirst,
            div.MsoListParagraphCxSpFirst {
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 0in;
                margin-left: .5in;
                font-family: "Calibri", serif;
            }
            
            p.MsoListParagraphCxSpMiddle,
            li.MsoListParagraphCxSpMiddle,
            div.MsoListParagraphCxSpMiddle {
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 0in;
                margin-left: .5in;
                font-family: "Calibri", serif;
            }
            
            p.MsoListParagraphCxSpLast,
            li.MsoListParagraphCxSpLast,
            div.MsoListParagraphCxSpLast {
                margin-top: 0in;
                margin-right: 0in;
                margin-bottom: 0in;
                margin-left: .5in;
                font-family: "Calibri", serif;
            }
            
            span.CommentTextChar {
                mso-style-name: "Comment Text Char";
                mso-style-link: "Comment Text";
            }
            
            .MsoChpDefault {
                font-family: "Calibri", sans-serif;
            }
            
            @page WordSection1 {
                size: 595.0pt 842.0pt;
                margin: 1.0in 1.0in 1.0in 1.0in;
            }
            
            div.WordSection1 {
                page: WordSection1;
            }
            /* List Definitions */
            
            ol {
                margin-bottom: 0in;
            }
            
            ul {
                margin-bottom: 0in;
            }
            
            -->
        </style>
    
    </head>
    
    <body lang=EN-US style='word-wrap:break-word'>
    
        <div class=WordSection1>
    
            <p class=titletext align=center style='text-align:center'><span lang=EN-IN>IMPORTANT:
            PLEASE READ THE TERMS AND CONDITIONS OF THIS END USER LICENSE AGREEMENT (“AGREEMENT”) CAREFULLY. BY ACCESSING THE AURELIUS TRAINING PROGRAM, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND THE TERMS AND CONDITIONS, AND AGREE TO BE BOUND BY THE AGREEMENT.</span></p>
    
            <p class=titletext align=center style='text-align:center'><span lang=EN-IN>IF
    YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, YOU SHOULD PROMPTLY EXIT FROM
    THIS TRAINING PROGRAM AND DISCONTINUE USAGE.</span></hp>
    
                <p class=MsoNormal><span lang=EN-IN>&nbsp;</span></p>
    
                <p class=MsoNormal><span lang=EN-IN>&nbsp;</span></p>
    
                <p class=MsoNormal><span lang=EN-IN>This Agreement constitutes a legal agreement between you and LeadUpp Private Limited (“LeadUpp”), the owner and operator of Aurelius – a game based learning application (“Training Program”). Its terms and conditions govern your use of the Training Program.  </span></p>
    
                <p class=MsoNormal><span lang=EN-IN>&nbsp;</span></p>
    
                <p class=MsoListParagraphCxSpFirst style='text-indent:-.25in'><span lang=EN-IN>1.<span
    style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang=EN-IN>License Grant:</span></p>
    
                <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>LeadUpp hereby grants to you a limited, non-exclusive, non-sublicensable, non-transferable, non-assignable, restricted license to access the Training Program according to the provisions contained herein. </span></p>
    
                <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>&nbsp;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=EN-IN>2.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>Ownership:</span></p>
    
                <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>LeadUpp is and remains the exclusive owner of all rights, titles, and interests (including copyright, patent, trade secret and other proprietary and intellectual property rights) in and to the Training Program. Nothing in this Agreement shall be construed as granting you any title or interest in or to the Training Program, except for the limited license as specified above. You agree not to contest the validity of LeadUpp’s rights or to perform any act/s adverse to LeadUpp’s rights.</span></p>
    
                <p class=MsoListParagraphCxSpLast><span lang=EN-IN>&nbsp;</span></p>
    
                <p class=MsoNormal style='margin-left:.5in'><span lang=EN-IN>You acknowledge and agree that any ideas, enhancements, improvements, additions or modifications to the Training Program suggested by you, and any and all Intellectual Property Rights contained therein, will become LeadUpp’s property, and you assign and agree to assign any and all right, title, and interest to any rights in such suggestions to LeadUpp.</span></p>
    
                <p class=MsoListParagraphCxSpFirst><span lang=EN-IN> </span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=EN-IN>3.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>User Information, Passwords and License Access:</span></p>
    
                <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>You will be asked to provide certain information to gain access to the Training Program. You agree that the information you provide is true, accurate and complete and that you will not register under a name of, or attempt to enter or use the Training Program under a name or ID that is not your own. You agree that it is your responsibility to maintain your access to the Training Program and keep your password and other information you provide confidential. You agree to immediately notify LeadUpp and your institution if there is any known or suspected unauthorized use of your password or the Training Program. </span></p>
    
                <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>&nbsp;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=EN-IN>4.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>License Restrictions:</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>i.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>You agree not to, or attempt to, duplicate, modify, sub-license, copy, adapt, manufacture, upload, publicly display, print, transmit, distribute, reproduce, download, market, lease, resell, incorporate in whole or in part in any other product or create derivative works based on all or any part of the Training Program;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>ii.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>You agree not to alter, obscure, remove, conceal or otherwise interfere with any tradenames, trademarks, copyrights or other proprietary markings on or in the Training Program;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>iii.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>You agree not to reverse compile, reverse assemble, reverse engineer, or otherwise reduce to human-perceivable form any part of the Training Program or to discover or disclose the source code, methods and concepts embodied in the Training Program;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>iv.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>You agree not to use the Training Program in a way that could damage, disable, overburden, impair or compromise LeadUpp’s systems or security or interfere with other users;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>v.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>You agree not to circumvent or manipulate any of the restrictions or security-related features within the Training Program;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>vi.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>You agree not to knowingly introduce any virus, worm, malware, spyware, Trojan horse or other harmful or malicious code to the Training Program. If you become aware of any security breach or misuse of any content that could compromise the security or integrity of the content or otherwise adversely affect the Training Program, you will notify LeadUpp immediately;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>vii.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>You agree not to access and use the Training Program in order to build a product or service competitive with the Training Program;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>viii.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>You agree to access the Training Program on a single device only. You agree not to share your license with any other user, create a false identity, or provide or offer access to any third parties.</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>ix.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>You agree not to disseminate, display, send, transmit or receive any material on or through the Training Program that, to a reasonable person may be abusive, obscene, pornographic, defamatory, harassing, grossly offensive, vulgar, threatening, malicious, or violent, regardless of whether the material or its dissemination is unlawful;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>x.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>You agree not to access, send, receive, display, disclose, or store any content on the Training Program in violation of any copyright, right of publicity, patent, trademark, service mark, trade name, trade secret or other intellectual property right or in violation of any applicable agreement, or without authorization;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>xi.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>You agree not to use the Training Program in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these terms, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses, or harmful data, into the Training Program. </span></p>
               
    <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>xii.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>You agree not to collect or harvest any information or data from the Training Program or underlying systems or attempt to decipher any transmissions to or from the servers running the Training Program.</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>xiii.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>You agree not to engage in any other activity deemed by the Company to be in conflict with the spirit or intent of the Agreement or any related/ referenced policy.</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>xiv.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>LeadUpp’s Company name, logo and all related names, logos, product and service names, designs and slogans are trademarks of LeadUpp or its affiliates or licensors. You agree not to use such marks without the prior written permission of LeadUpp.</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in;margin-top:10px;'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span lang=EN-IN>Failure to comply</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in;margin-top:10px;'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>&nbsp;&nbsp;&nbsp;&nbsp;<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN><span lang=EN-IN>Violation of these restrictions in LeadUpp’s reasonable judgment may result in the immediate termination of access to the Training Program, responding to law enforcement requests, or any other action deemed necessary by us in order to protect our network, customer relationships, and commitment to the highest possible quality of service. LeadUpp will cooperate with law enforcement agencies in cases where the Training Program is being used by You for any suspected illegal activity</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in;margin-top:10px;'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span lang=EN-IN>Reporting violations</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in;margin-top:10px;'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>&nbsp;&nbsp;&nbsp;&nbsp;<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN><span lang=EN-IN>Violations of the License Restrictions are unethical and may be deemed as an offence. You shall report to LeadUpp, any information that you may have concerning instances in which these restrictions have been or are being violated. We may at any time initiate an investigation of any use of the Training Program for compliance with this Agreement and You agree to cooperate.</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in;margin-top:10px;'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span lang=EN-IN>Malicious activity</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in;margin-top:10px;'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>a.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN><span lang=EN-IN>Intended: Violations of system or network security may result in criminal and civil liability. We will cooperate with law enforcement if a criminal violation is suspected. We will limit any traffic from the offending device or network immediately.</span></p>
        
    <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in;margin-top:10px;'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>b.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN><span lang=EN-IN>Unintended: We will notify you of an exploited software being used for potential malicious activity. If the activity is causing severe damage or strain, we will limit traffic to and from that software immediately.</span></p>


                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in'><span lang=EN-IN>&nbsp;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>&nbsp;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=EN-IN>5.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>Third Party components: </span></p>
    
                <p class=MsoListParagraphCxSpLast><span lang=EN-IN>The Training Program may include certain third-party software. Any third-party software delivered to you pursuant to this Agreement, including any third-party plug-in that may be provided with the Training Program, is included for use solely in accordance with the terms of this Agreement and the corresponding third-party software license(s).</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in'><span lang=EN-IN>&nbsp;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>&nbsp;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=EN-IN>6.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>Confidentiality: </span></p>
    
                <p class=MsoListParagraphCxSpLast><span lang=EN-IN>Any information or materials provided by LeadUpp to you, including, without limitation, the training modules and the Training Program, will be considered confidential and proprietary information (“Confidential Information”). You agree to protect the Confidential Information from unauthorized use or disclosure and agree not to disclose to any third party such Confidential Information except as expressly permitted under this Agreement. Information will not be considered Confidential Information if it (a) is or becomes publicly known without your fault, breach of contract or negligence, or (b) is lawfully disclosed to you by a third party who is not subject to any duty of confidentiality. You are granted no right, title or license of LeadUpp’s Confidential Information or any intellectual property rights therein. All rights and titles to LeadUpp’s Confidential Information shall remain solely with LeadUpp. </span></p>
                
                <p class=MsoListParagraphCxSpLast style='margin-top:10px'><span lang=EN-IN>You understand and agree that damages may not be a sufficient remedy for any breach of the confidentiality provisions of this Agreement and that LeadUpp shall be entitled to equitable relief, including injunction and specific performance, as a remedy for any such breach. Such remedies may not be deemed exclusive remedies for a breach of these provisions but may be deemed in addition to all other remedies available at law or in equity. </span></p>
    
                <p class=MsoNormal><span lang=EN-IN>&nbsp;</span></p>
    
                <p class=MsoListParagraphCxSpFirst style='text-indent:-.25in'><span lang=EN-IN>7.<span
    style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang=EN-IN>Data Privacy:</span></p>
    
                <p class=MsoListParagraphCxSpLast><span lang=EN-IN>LeadUpp will process
    information received from you or otherwise hold information about you in
    accordance with the terms of LeadUpp’s <a target="_blank"
    href="https://www.leadupp.co/privacypolicyleadupp.html">Privacy Policy</a>.
    You consent to LeadUpp’s use of such information in accordance with LeadUpp’ privacy
    policy.</span></p>
    
                <p class=MsoNormal><span lang=EN-IN>&nbsp;</span></p>
    
                <p class=MsoListParagraphCxSpFirst style='text-indent:-.25in'><span lang=EN-IN>8.<span
    style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang=EN-IN>NO WARRANTY; LIMITATION OF LIABILITY: </span></p>
    
                <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>THE TRAINING PROGRAM IS PROVIDED WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING ANY IMPLIED WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES ARISING FROM A COURSE OF DEALING, USAGE, OR TRADE PRACTICE. LEADUPP MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY, COMPLETENESS, TIMELINESS, RELIABILITY, LEGALITY, OR ACCURACY OF THE INFORMATION, PROGRAMS, PRODUCTS, SERVICES, AND MATERIALS ASSOCIATED WITH THE TRAINING PROGRAM FOR ANY PURPOSE.  LEADUPP WILL NOT BE RESPONSIBLE TO YOU FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES OF ANY KIND, INCLUDING WITHOUT LIMITATION, LOST PROFITS OR OTHER MONETARY LOSS, ARISING OUT OF PERFORMANCE OF SERVICES OR FAILURE TO PERFORM OR DUE TO THE FAILURE OR POOR PERFORMANCE UNDER THIS AGREEMENT, WHETHER OR NOT ANY SUCH FAILURES OR CAUSES ARE WITHIN LEADUPP’S CONTROL OR DUE TO NEGLIGENCE OR OTHER FAULT OF THE PARTY, ITS AGENTS, AFFILIATES, EMPLOYEES OR OTHER REPRESENTATIVES. LEADUPP DOES NOT WARRANT THAT THE TRAINING PROGRAM WILL MEET YOUR REQUIREMENTS OR THAT THE OPERATION THEREOF WILL BE FAIL SAFE, UNINTERRUPTED, ERROR FREE.
    </span></p>
    
                <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>&nbsp;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=EN-IN>9.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>Indemnification:</span></p>
    
                <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>You will defend, indemnify and hold harmless, LeadUpp and its affiliates, service providers, suppliers, partners, and their respective directors, officers, employees and agents, from and against any third party claims, suits or actions and any resulting damages, costs, liabilities and expenses (including, but not limited to reasonable attorneys' fees) arising out of or related to: (a) your use of, or inability to use, the Training Program; or (b) your violation of any terms of this Agreement. </span></p>

                <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>&nbsp;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=EN-IN>10.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>Termination:</span></p>
    
                <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>LeadUpp may terminate your access to and use of the Training Program at any time, with or without cause, and without notice. In addition, this Agreement will automatically terminate if you violate any of its terms. Upon termination of this Agreement, your limited license to use the Training Program will automatically terminate and you must destroy any copy you have of the Training Program or any of its components. Sections 2-9 of this Agreement will survive the termination or expiration of the Agreement. </span></p>
    
                <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>&nbsp;</span></p>
    
                <p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span lang=EN-IN>11.<span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN>General Provisions:</span></p>
    
      <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in;margin-top:10px;'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span><span lang=EN-IN style='text-decoration:underline;'>Force Majeure</span></p>
                <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>LeadUpp is not liable for any loss, damage, delay or failure to perform in whole or in part resulting from causes beyond its control, including, but not limited to network failures, fires, earthquakes, strikes, delays in transportation, epidemic, embargos, or acts of any governmental authority. </span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in;margin-top:10px;'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span><span lang=EN-IN style='text-decoration:underline;'>Entire Agreement</span></p>
                <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>This Agreement constitutes the entire agreement between you and LeadUpp and may be amended by LeadUpp by providing written notice to you. Such amended Agreement will be effective immediately and incorporated into this Agreement upon posting the revised agreement on LeadUpp’s website / within the Training Program. Your use of the Training Program after any amendment shall signify your acceptance of the Agreement, as amended. </span></p>
                
                <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in;margin-top:10px;'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span><span lang=EN-IN style='text-decoration:underline;'>Waiver</span></p>
                  <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>No failure or delay by LeadUpp in exercising any right, power or privilege under this Agreement shall operate as a waiver thereof. </span></p>
              
                  <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in;margin-top:10px;'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span><span lang=EN-IN style='text-decoration:underline;'>Assignment</span></p>
                  <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>LeadUpp may assign this Agreement at its discretion, but you may not assign this Agreement or any of your rights or obligations hereunder without LeadUpp’s express, written consent. </span></p>
                
                  <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in;margin-top:10px;'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span><span lang=EN-IN style='text-decoration:underline;'>Severability</span></p>
                  <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>If any provision hereof is found to be invalid or unenforceable, the remaining provisions hereof will remain in full force and effect. </span></p>
                 
                  <p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-indent:-1.0in;margin-top:10px;'><span lang=EN-IN><span style='font:7.0pt "Calibri"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span><span lang=EN-IN style='text-decoration:underline;'>Governing Law and Jurisdiction</span></p>
                  <p class=MsoListParagraphCxSpMiddle><span lang=EN-IN>This Agreement is governed by the laws of India. Any claim arising from or related to this Agreement must be brought in courts located in Mumbai.. </span></p>
                
               
    
                <p class=MsoNormal><span lang=EN-IN>&nbsp;</span></p>
    
                <p class=MsoNormal><span lang=EN-IN>TO ACCESS THIS TRAINING PROGRAM, PLEASE INDICATE YOUR AGREEMENT TO THESE TERMS:</span></p>
    
                <p class=MsoNormal><span lang=EN-IN>&nbsp;</span></p>
    
                <p class=MsoNormal><span lang=EN-IN>Yes, I agree to be the ONLY person using this License, I Understand that I am not authorised to share or distribute this license or the content and doing so will breach this agreement.</span></p>
    
                <p class=MsoNormal><span lang=EN-IN>Yes, I agree to ONLY use this License for my own training purposes.</span></p>
    
                <p class=MsoNormal><span lang=EN-IN>Yes, I accept and agree to be bound by all terms of the End User License Agreement as stated above.</span></p>
    
                <p class=MsoNormal><span lang=EN-IN>&nbsp;</span></p>
    
    
                <p class=MsoNormal><span lang=EN-IN>&nbsp;</span></p>
    
        </div>
    
        <div>
    
            <hr class=msocomoff align=left size=1 width="33%">
    
            <div>
    
                <div id="_com_1" class=msocomtxt language=JavaScript onmouseover="msoCommentShow('_anchor_1','_com_1')" onmouseout="msoCommentHide('_com_1')">
    
    
    
                </div>
    
            </div>
    
        </div>
    
    </body>
    
    </html>` };
  }


  render() {
    const { loading } = this.state
    return (
      <div className="gx-app-login-wrap" >
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">

                {/* <img src={"https://via.placeholder.com/272x395"} alt='Neature' /> */}
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.signIn" /></h1>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")} />
              </div>
            </div>
            <div className="gx-app-login-content">

              <Form
                initialValues={{ remember: true }}
                name="basic"
                onFinish={this.onFinish}
                className="gx-signin-form gx-form-row0">
                <InputPrefix
                  initialValue="demo@example.com"
                  label=''
                  name="email"
                  autoComplete={false}
                  validationMessage='enter.valid.email'
                  requiredMessage='input.email'
                  email={true}
                />
                <InputPrefix
                  label=''
                  name="password"
                  validationMessage='Please input your Password!'
                  requiredMessage='Please input your Password!'
                />

                <Form.Item>
                  <Button type="primary" className="gx-mb-0" htmlType="submit" loading={loading}>
                    Log in
                  </Button>

                  <Link
                    type="primary"
                    to={`/reset-password`}
                  >
                    Forgot Password ?
                  </Link>
                </Form.Item>
                <Modal maskClosable={false} className='signinPopup' title="END USER LICENSE AGREEMENT (EULA)" okText={'Accept'} cancelText={'Decline'} visible={this.state.isShowPopup} onOk={() => this.handleSubmitEula()} onCancel={() => this.setState({ isShowPopup: false })}>
                  <p dangerouslySetInnerHTML={this.createMarkup()} />
                </Modal>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );

  }
}


const mapStateToProps = ({ settings }) => {
  const { setInitUrl, title, url, setProfile } = settings;
  return { setInitUrl, title, url, setProfile }
};
const mapDispatchToProps = dispatch => {
  return {
    clearAuth: () => dispatch(clearCredentials()),
    userSignInSuccess: (token) => dispatch(userSignInSuccess(token))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);

