import ApiHelper from "./ApiHelper";

class AppearanceService {
  
    static async GetAppearanceLogo(body) {
        return ApiHelper.getAuthenticated("organization/get-logo", body);
      }
  
}
export default AppearanceService;
