import React, { Component } from 'react'

import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { connect } from "react-redux";
import ProfileService from '../../service/ProfileService';
import { withRouter } from 'react-router-dom';
import * as urlConfig from '../../constants/URLConstant';
import AuthService from '../../service/AuthService';

export class UserProfile extends Component {
  constructor() {
    super()
    this.state = {
      auditLogging: "",
      data: null,
      profile: null,
      defaultProfile: null
    }
  }
  componentDidMount() {
    this.fetchProfileImage()
  }
  fetchProfileImage = async () => {

        this.setState({
          profile: ''
        });
     
  };
  onProfileClick = () => {
    var profile = AuthService.GetCurrentLoggedUserDetails();
    if (profile.role === "SECRETORY") {
      this.props.history.push({ pathname: urlConfig.SECRETORY_BASIC_INFO });
    } else if (profile.role === "DOCTOR") {
      this.props.history.push({ pathname: urlConfig.DOCTOR_BASIC_INFO });
    } else if (profile.role === "NURSE") {
      this.props.history.push({ pathname: urlConfig.NURSE_BASIC_INFO });
    } else if (profile.role === "PATIENT") {
      this.props.history.push({ pathname: urlConfig.PATIENT_BASIC_INFO });
    } else if (profile.role === "SUPER_ADMIN") {
      this.props.history.push({ pathname: urlConfig.SUPER_ADMIN_BASIC_INFO });
    }

  }
  render() {
    const { userDetails } = this.props.userDetails

    const userMenuOptions = (
      <ul className="gx-user-popover">
        {/* <li onClick={() => this.onProfileClick()}>My Profile</li> */}
        {/* <li>Connections</li> */}
        <li onClick={() => this.props.userSignOut()}>Logout
        </li>
      </ul>
    )
    return (





      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          {/* {this.props.setProfile ? (
            this.props.setProfile === "DefaultImage" ? (
              <Avatar src={`data:image/png;base64,${this.state.defaultProfile}`}
                className="gx-size-40 gx-pointer gx-mr-3" alt="" />
            ) : (
                <Avatar
                  src={this.props.setProfile}
                  className="gx-size-40 gx-pointer gx-mr-3"
                  alt=""
                />
              )
          ) : this.state.profile ? (
            <Avatar
              src={`data:image/png;base64,${this.state.profile}`}
              className="gx-size-40 gx-pointer gx-mr-3"
              alt=""
            />
          ) : (
                <Avatar
                  src={this.state.defaultProfile}
                  className="gx-size-40 gx-pointer gx-mr-3"
                  alt=""
                />
              )} */}
          {/* <Avatar src={`data:image/png;base64,${this.state.profile}`}
            className="gx-size-40 gx-pointer gx-mr-3" alt="" /> */}
          <span className="gx-avatar-name">{userDetails.name} <i
            className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
        </Popover>
      </div>



    )
  }
}





const mapStateToProps = ({ settings }) => {
  const { navStyle, verticalNavStyle, horizontalNavPosition, locale, width, navCollapsed, setTime, setProfile,
    name,
    title } = settings;
  return {
    navStyle, verticalNavStyle, horizontalNavPosition, locale, width, navCollapsed, setTime, setProfile,
    name,
    title
  }
};

export default withRouter(connect(mapStateToProps, { userSignOut })(UserProfile));
