import React, { PureComponent } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import URLSearchParams from 'url-search-params'
import { Redirect, Route, Switch, } from "react-router-dom";
import { removeUIError, setTime, setTitle, setURL } from '../../appRedux/actions';
import { ConfigProvider } from 'antd';
import { IntlProvider } from "react-intl";
import Session from 'store2';
import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../../components/AppModule/Authentication/SignIn";
import SignUp from "../SignUp";
import { setInitUrl } from "appRedux/actions/Auth";
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from "appRedux/actions/Setting";
import ResetPassword from '../../components/AppModule/Authentication/ForgotPasswordSentMail'
import NewPassword from '../../components/AppModule/Authentication/NewPassword/index'
import ChangePassword from '../../components/AppModule/Authentication/ForgotPasswordVerifyMail'
import VerifyOTP from '../../components/AppModule/Authentication/VerifyOTP'
import UserContext from '../../contexts/UserContext';
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from "../../constants/ThemeSetting";
import AuthService from "../../service/AuthService";
import * as urlConstant from '../../constants/URLConstant';
import CircularProgress from "../../components/AppModule/CircularProgress";
import ReactPlaceholder from "react-placeholder";
import ConfirmEmail from '../../components/AppModule/Authentication/ConfirmMail'

const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: { from: location }
          }}
        />}
  />;


class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      authenticated: false,
      userDetails: null,
      logginStatus: true,
      login: async () => {
        const userDetails = await AuthService.GetCurrentLoggedUserDetails();
        this.setState({ authenticated: true, userDetails });
      },
      logout: () => this.setState({ authenticated: false, userDetails: null }),
    };
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];

    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }

  clearTimeout() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    // this.warnTimeout = setTimeout(this.warn, 16 * 1000);
    if (this.props.location.pathname === `/login`) {
    } else {
      this.props.setTime(Date.now())
      this.warnTimeout = setTimeout(this.logout, 60000 * 1000);
    }
    // this.logoutTimeout = setTimeout(this.logout, 16 * 1000);
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  logout() {
    // alert("You will be logged out automatically in 1 minute.");
    this.props.history.push(`/logout`);
  }



  destroy() {
    this.clearTimeout();

    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.error) {
      this.props.removeUIError()
    }
  }

  componentDidMount() {
    this.setLinkData()
    this.validateToken();
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    } else {
      if (this.props.history.location.search === '') {
        this.props.setURL(this.props.history.location.pathname);
      } else {
        this.props.setURL(this.props.history.location.pathname + this.props.history.location.search);
      }
    }

    const params = new URLSearchParams((this.props.history.location.search))
    if (params.has("theme")) {
      this.props.setThemeType(params.get('theme'))
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get('nav-style'))
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get('layout-type'))
    }
    this.setLayoutType(this.props.layoutType);
    this.setNavStyle(this.props.navStyle);

 

    if (this.props.history.location.pathname === '/') {
      if (this.props.authUser === null) {
        this.props.history.push('/signin');
      } else if (this.props.initURL === '' || this.props.initURL === '/' || this.props.initURL === '/signin') {
        this.props.history.push('/signin');
      } else {
        this.props.history.push(this.props.initURL);
      }
    }

    if (this.props.themeType === THEME_TYPE_DARK) {
      console.log("adding dark class")
      document.body.classList.add('dark-theme');
      document.body.classList.add('dark-theme');
      let link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = "/css/dark_theme.css";
      link.className = 'style_dark_theme';
      document.body.appendChild(link);
    }
  }

  setLinkData = () => {
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = `/css/${this.props.themeColor}.css`;

    link.className = 'gx-style';
    document.body.appendChild(link);
  }

  validateToken = async () => {
    // Validate token
    if (Session.session('userAccessToken')) {
      const userDetails = await AuthService.GetCurrentLoggedUserDetails();
      const response = await AuthService.ValidateToken();
      if (response.status === urlConstant.SUCCESS_CODE) {
        this.setState({ loading: false, authenticated: true, userDetails: userDetails })
      } else {
        this.setState({ loading: false, authenticated: false })
      }
    } else {
      this.setState({ loading: false, authenticated: false })
    }
  }

  setClient = (clientData) => {
    this.setState({ client: clientData })
  }


  validateToken = async () => {
    // Validate token
    if (Session.session('userAccessToken')) {
      const userDetails = await AuthService.GetCurrentLoggedUserDetails();
      const response = await AuthService.ValidateToken();
      if (response.status === urlConstant.SUCCESS_CODE) {
        this.setState({ loading: false, authenticated: true, userDetails: userDetails })
      } else {
        this.setState({ loading: false, authenticated: false })
      }
    } else {
      this.setState({ loading: false, authenticated: false })
    }
  }

  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };






  render() {
    const currentAppLocale = AppLocale[this.props.locale.locale];
    const { match, location, layoutType, locale, error, authUser } = this.props;

    if (this.state.loading)
      return (
        <ReactPlaceholder type="text" rows={7} ready={PureComponent !== null}>
          <CircularProgress />
        </ReactPlaceholder>
      );
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <UserContext.Provider value={this.state}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>

            <Switch>
              <Route exact path='/signin' component={SignIn} />
              <Route exact path='/signup' component={SignUp} />
              <Route path={`/verify-otp`} component={VerifyOTP} />
              <Route path={`/reset-password`} render={props => <ResetPassword {...props} />} />
              <Route path={`/new-password`} render={props => <NewPassword {...props} />} />
              <Route path={`/change-password`} render={props => <ChangePassword {...props} />} />
              <Route path={`/invitation/confirm-email`} render={props =>
                    <ConfirmEmail {...props} />} />
              <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location}
                component={MainApp} />
            </Switch>
          </IntlProvider>
        </UserContext.Provider>
      </ConfigProvider>
    )
  }
}

const mapStateToProps = ({ settings, errorUI, auth }) => {
  const { locale, layoutType, setTime, title, url, themeType, navStyle, themeColor } = settings;
  const { authUser, initURL } = auth;
  const { error } = errorUI;
  return { locale, layoutType, error, setTime, title, url, authUser, initURL, navStyle, themeType, themeColor }
};

const mapDispatchToProps = dispatch => {
  return {
    removeUIError: () => dispatch(removeUIError()),
    setInitUrl: (url) => dispatch(setInitUrl(url)),
    setTime: (time) => dispatch(setTime(time)),
    setTitle: (title) => dispatch(setTitle(title)),
    setURL: (url) => dispatch(setURL(url)),
    setThemeType: (theme) => dispatch(setThemeType(theme)),
    onNavStyleChange: (navStyle) => dispatch(onNavStyleChange(navStyle)),
    onLayoutTypeChange: (layoutType) => dispatch(onLayoutTypeChange(layoutType))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);


