import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
  SIGNIN_USER,
  SIGNOUT_USER,
} from "constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess} from "../../appRedux/actions/Auth";



function* signInUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
 
    
      localStorage.setItem('user_id', 'signInUser.user.uid');
      yield put(userSignInSuccess('signInUser.user.uid'));
    
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
      localStorage.removeItem('user_id');
      yield put(userSignOutSuccess('signOutUser'));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}




export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser),
    fork(signOutUser)]);
}
