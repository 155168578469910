import React from "react";
import { Button, Form, Input, Alert, message, Tooltip, Spin } from "antd";
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive';
import * as urlConfig from '../../../../constants/URLConstant';
// import loginLogo from '../../../../assets/images/logo/logo.png';
import Session from 'store2';
import AuthService from "../../../../service/AuthService";
import './Auth.css'
import AppearanceService from "../../../../service/AppearanceService";
import { LockOutlined } from "@ant-design/icons";

const FormItem = Form.Item;
const containDigits = "(?=.*[0-9])";
const dataDigits = "0-9";
const containLetters = "A-Za-z";
const containUpperCase = "(?=.*[A-Z])";
const dataUpperCase = "A-Z";
const containLowerCase = "(?=.*[a-z])";
const dataLowerCase = "a-z";
const containSymbol = "(?=.*[a-z])"
const dataSymbol = "a-z"
const containSpecialCharacter = "(?=.*[!@#$%^&*)(+=._-])"
const dataSpecialCharacter = "!@#$%^&*)(+=._-"
var minimumLenght = 10
var maximunLengh = 16
var data = ''
var array = ''
var tooltip = ''
var tooltipData = []

class ConfirmEmail extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            submitError: false,
            error: '',
            loading: false,
            capsLockWarning: false,
            submitted: false,
            showConfirmPassword: false,
            spinLoading: false,
            encrypted_data: "",
            passWord: "",
            name: '',
            logo: null,
            newRegEx: '',
            firstPassword: '',
            allTooltipData: [],
            userdata: {
                digits: '',
                id: '',
                is_active: '',
                letters: '',
                lowercase: '',
                maximum_length: '',
                minimum_length: '',
                organization_id: '',
                special_characters: '',
                symbols: '',
                uppercase: ''
            },
            digits: false,
            letters: false,
            lowercase: false,
            maximum_length: false,
            minimum_length: false,
            organization_id: false,
            special_characters: false,
            symbols: false,
            uppercase: false,
            lowercaseData: 'Should be one Lowercase character : a-z'
        };
    }

    formRef = React.createRef()


    componentWillMount() {
        var data = this.props.location.search;
        let params = new URLSearchParams(data);
        let value = params.get('value');
        let name = params.get('name');
        if (value && value !== null && name && name !== null) {
            this.setState({ encrypted_data: value, name: name });
        }
    }

    compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== this.formRef.current.getFieldValue('password')) {
            callback('Two passwords that you entered are inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        if (value && this.state.confirmDirty) {
            this.formRef.current.validateFields(['confirm'], { force: true });
        }
        this.setState({
            firstPassword: value
        })
        if (this.state.newRegEx.test(value)) {
            this.setState({ showConfirmPassword: true })
        } else if (!this.state.newRegEx.test(value)) {
            this.setState({ showConfirmPassword: false })
        }
        callback();
    };

    handleSubmit = async (values) => {
        this.setState({ loading: true });
        this.confirmEmail(values)
    };
    
    confirmEmail = async (formdata) => {
        const response = await AuthService.ConfirmEmail(formdata)

        if (response.status === urlConfig.SUCCESS_CODE) {
            this.setState({ loading: false });
            const { from } = this.props.location.state || { from: { pathname: `/login` } };
            message.success(response.message)
            this.props.history.push(from);
        }
        else {
            this.setState({ loading: false });
            message.error(response.message);

        }
    }
    componentDidMount = async () => {
        this.setState({ spinLoading: true })
        var data = this.props.location.search;
        let params = new URLSearchParams(data);
        let value = params.get('value');
        if (value && value !== null) {
            this.setState({ encrypted_data: value });
            // this.getPasswordPolicy(value)
        } else {
            this.setState({
                userdata: null,
                spinLoading: false
            })


            this.setRegEx()
        }
        const response = await AppearanceService.GetAppearanceLogo()
        if (response.status === urlConfig.SUCCESS_CODE) {
            if (response.data !== null) {
                this.setState({ logo: response.data })
                Session.session("logo", response.data)
            }

        }
    }

    resetPassword = () => {
        // this.setState({ userName: "", passWord: "" })
        this.props.history.push(`/login`);
    }
    setRegEx = () => {

        if (this.state.userdata !== null) {
            const { digits,
                letters,
                lowercase,
                maximum_length,
                minimum_length,
                special_characters,
                symbols,
                uppercase } = this.state.userdata
            minimumLenght = minimum_length ? minimum_length : 6;
            maximunLengh = maximum_length ? maximum_length : 16;
            if (digits) {
                data = containDigits
                array = dataDigits
                tooltip = tooltipData.push("Should be one Number : 0-9")
                this.setState({ digits: true })
            }
            if (letters) {
                if (uppercase && lowercase) {
                    data = data + containUpperCase + containLowerCase
                    array = array + containLetters
                    tooltip = tooltipData.push("Should be one Uppercase character : A-Z")
                    tooltip = tooltipData.push('Should be one Lowercase character : a-z')
                    this.setState({ uppercase: true, lowercase: true })
                }
                else if (uppercase) {
                    data = data + containUpperCase
                    array = array + dataUpperCase
                    tooltip = tooltipData.push("Should be one Uppercase character : A-Z")
                    this.setState({ uppercase: true })
                } else if (lowercase) {
                    data = data + containLowerCase
                    array = array + dataLowerCase
                    tooltip = tooltipData.push(this.state.lowercaseData)
                    this.setState({ lowercase: true })
                }

            }
            if (symbols) {
                data = data + containSymbol
                array = array + dataSymbol
                tooltip = tooltipData.push('Symbol : !,@,#,$,%,^,*,(,)')
                this.setState({ symbols: true })
            }
            if (special_characters) {
                data = data + containSpecialCharacter
                array = array + dataSpecialCharacter
                tooltip = tooltipData.push('Should be one Special character: !@#$%^&*)(+=._-')
                this.setState({ special_characters: true })
            }
            if (!digits && !letters && !symbols && !special_characters) {
                data = data + containUpperCase + containLowerCase + containDigits
                array = containLetters + dataDigits
                tooltip = tooltipData.push("Should be one Uppercase character : A-Z")
                tooltip = tooltipData.push('Should be one Lowercase character : a-z')
                tooltip = tooltipData.push("Should be one Number : 0-9")
                this.setState({ uppercase: true, lowercase: true, digits: true })
            }
            tooltip = tooltipData.push(`${"Minimum password length " + minimumLenght}`)
            tooltip = tooltipData.push(`${"Maximum password length " + maximunLengh}`)
            // tooltipData = tooltipData && tooltipData.map(data => data)
            tooltipData = <span>Password must:
      <ul>
                    {tooltipData && tooltipData.map(data => <li>{data}</li>)}
                    {/* <li>Be at least {minimumLenght} characters</li>
          <li>Maximum {maximunLengh} characters</li> */}
                </ul>
            </span>
        } else {
            minimumLenght = 6;
            maximunLengh = 16;
            data = data + containUpperCase + containLowerCase + containDigits
            array = containLetters + dataDigits
            tooltip = tooltipData.push("Should be one Uppercase character : A-Z")
            tooltip = tooltipData.push('Should be one Lowercase character : a-z')
            tooltip = tooltipData.push("Should be one Number : 0-9")
            tooltip = tooltipData.push(`${"Minimum password length " + minimumLenght}`)
            tooltip = tooltipData.push(`${"Maximum password length " + maximunLengh}`)
            tooltipData = <span>Password must:
            <ul>
                    {tooltipData && tooltipData.map(data => <li>{data}</li>)}
                    {/* <li>Be at least {minimumLenght} characters</li>
                <li>Maximum {maximunLengh} characters</li> */}
                </ul>
            </span>
            this.setState({ uppercase: true, lowercase: true, digits: true })

        }
        const matchRegEx = new RegExp("^" + data + "[" + array + "]{" + minimumLenght + "," + maximunLengh + "}$", 'g')
        this.setState({ newRegEx: matchRegEx, allTooltipData: tooltipData })
        console.log(tooltip)
    }

    passwordChange = (value) => {
        var tooltipData2 = []
        var data = false


        if (this.state.digits) {
            if (/([0-9]+)/g.test(value.target.value)) {
                tooltipData2.push(<b style={{ color: 'green' }}>Should be one Number : 0-9</b>)
                data = true
            } else {
                tooltipData2.push(<b style={{ color: 'red' }}>Should be one Number : 0-9</b>)
            }
        } else if (/([0-9]+)/g.test(value.target.value)) {
            tooltipData2.push(<b style={{ color: 'red' }}>Should Not allow Number : 0-9</b>)
            data = true
        }

        if (this.state.uppercase) {
            if (/([A-Z]+)/g.test(value.target.value)) {
                tooltipData2.push(<b style={{ color: 'green' }}>Should be one Uppercase character : A-Z</b>)
                data = true
            } else {
                tooltipData2.push(<b style={{ color: 'red' }}>Should be one Uppercase character : A-Z</b>)
            }

        } else if (/([A-Z]+)/g.test(value.target.value)) {
            tooltipData2.push(<b style={{ color: 'red' }}>Should Not allow Uppercase character : A-Z</b>)
            data = true
        }

        if (this.state.lowercase) {
            if (/([a-z]+)/g.test(value.target.value)) {
                tooltipData2.push(<b style={{ color: 'green' }}>Should be one Lowercase character : a-z</b>)
                data = true
            } else {
                tooltipData2.push(<b style={{ color: 'red' }}>Should be one Lowercase character : a-z</b>)
            }

        } else if (/([a-z]+)/g.test(value.target.value)) {
            tooltipData2.push(<b style={{ color: 'red' }}>Should Not allow Lowercase character : a-z</b>)
            data = true
        }

        if (this.state.symbols) {
            if (/([-!$%^&*#+?_=:';]+)/g.test(value.target.value)) {
                tooltipData2.push(<b style={{ color: 'green' }}>Symbol : !,@,#,$,%,^,*,(,)</b>)
                data = true
            } else {
                tooltipData2.push(<b style={{ color: 'red' }}>Symbol : !,@,#,$,%,^,*,(,)</b>)
            }
        }
        if (this.state.special_characters) {
            if (/([!@#$%^&*)(+=._-]+)/g.test(value.target.value)) {
                tooltipData2.push(<b style={{ color: 'green' }}>Should be one Special character: !@#$%^&*)(+=._-</b>)
                data = true
            } else {
                tooltipData2.push(<b style={{ color: 'red' }}>Should be one Special character: !@#$%^&*)(+=._-</b>)
                // data = false
            }
        } else if (/([:'~}{|?<>,;"/`!@#$%^&*)(+=._-]+)/g.test(value.target.value)) {
            tooltipData2.push(<b style={{ color: 'red' }}>Shoud Not allow Special character</b>)
            data = true
        }
        var pattern = new RegExp("[" + value.target.value + "]{" + minimumLenght + ",}$", 'g')
        if (value.target.value.match(pattern)) {
            tooltipData2.push(<b style={{ color: 'green' }}>Minimum password length {minimumLenght}</b>)
            data = true
        } else {
            tooltipData2.push(<b style={{ color: 'red' }}>Minimum password length {minimumLenght}</b>)
        }
        var pattern1 = new RegExp("[" + value.target.value + "]{" + maximunLengh + "}$", 'g')
        if (value.target.value.match(pattern1)) {
            if (value.target.value.length === maximunLengh) {
                tooltipData2.push(<b style={{ color: 'green' }}>Maximum password length {maximunLengh}</b>)
                data = true
            } else {
                tooltipData2.push(<b style={{ color: 'red' }}>Maximum password length {maximunLengh}</b>)
            }

        } else {
            tooltipData2.push(<b style={{ color: 'green' }}>Maximum password length {maximunLengh}</b>)
        }
        if (/([^\S]+)/g.test(value.target.value)) {
            tooltipData2.push(<b style={{ color: 'red' }}>Should not allow white space </b>)
            data = true
        }
        var tooltipData1 = <span>Password must:
        <ul>
                {tooltipData2 && tooltipData2.map(data => <li>{data}</li>)}

            </ul>
        </span>
        if (data) {
            this.setState({ allTooltipData: tooltipData1 })

        } else {
            this.setState({ allTooltipData: tooltipData })

        }
    }

    render() {
        const { error, encrypted_data, showConfirmPassword } = this.state;

        return (
            <React.Fragment>
                <div className="gx-app-login-wrap">
                    <div className="gx-app-login-container">
                        <div className="gx-app-login-main-content">
                            <div className="gx-app-logo-content">
                                <div className="gx-app-logo-content-bg">

                                    {/* <img src={"https://via.placeholder.com/272x395"} alt='Neature' /> */}
                                </div>
                                <div className="gx-app-logo-wid">
                                    <h1>Welcome, {this.state.name}</h1>
                                    {/* <p><IntlMessages id="app.userAuth.bySigning" /></p> */}
                                    {/* <p><IntlMessages id="app.userAuth.getAccount" /></p> */}
                                </div>
                                <div className="gx-app-logo">
                                    <img alt="example" src={require("assets/images/logo.png")} />
                                </div>
                            </div>
                            <div className="gx-app-login-content">
                                <Spin tip="Loading..." spinning={this.state.spinLoading}>
                                    <Form onFinish={this.handleSubmit} ref={this.formRef} layout="vertical" className="gx-login-form gx-form-row0">
                                        {
                                            error && (
                                                <FormItem>
                                                    <Alert
                                                        message={error}
                                                        type="error"
                                                        closable
                                                    />
                                                </FormItem>
                                            )
                                        }
                                        <FormItem>
                                            <ReactIsCapsLockActive>
                                                {
                                                    active => active
                                                        ? <Alert
                                                            message='Warning : Caps lock is on'
                                                            type="warning"
                                                            closable
                                                        />
                                                        : null
                                                }
                                            </ReactIsCapsLockActive>
                                        </FormItem>
                                        <Form.Item
                                            style={{ display: 'none' }}
                                            name='encrypted_data'
                                            rules={[
                                                {
                                                    required: false
                                                }
                                            ]}
                                            initialValue={encrypted_data}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Tooltip placement="bottom" title={this.state.allTooltipData}>
                                            <Form.Item
                                                label="New password"
                                                name='password'
                                                rules={[
                                                    {
                                                        message: 'Please enter correct Credentials',
                                                        pattern: new RegExp("^" + data + "[" + array + "]{" + minimumLenght + "," + maximunLengh + "}$", 'g')
                                                    },
                                                    {
                                                        required: true,
                                                        message: 'Please input your new password!',
                                                    },
                                                    {
                                                        validator: this.validateToNextPassword,
                                                    },
                                                ]} hasFeedback>

                                                <Input.Password
                                                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    onChange={this.passwordChange}
                                                    maxLength={maximunLengh}
                                                ></Input.Password>

                                            </Form.Item>
                                        </Tooltip>
                                        {showConfirmPassword ?
                                            <Form.Item
                                                label="Confirm Password"
                                                hasFeedback
                                                name='confirm'
                                                rules={
                                                    [{
                                                        required: true,
                                                        message: 'Please confirm your password!',
                                                    },
                                                    {
                                                        validator: this.compareToFirstPassword,
                                                    }]}
                                            >

                                                <Input.Password
                                                    onBlur={this.handleConfirmBlur}
                                                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    maxLength={maximunLengh}
                                                />
                                            </Form.Item>
                                            :
                                            <Form.Item
                                                label="Confirm Password"
                                                hasFeedback
                                                name='confirm'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please confirm your password!',
                                                    },
                                                    {
                                                        validator: this.compareToFirstPassword,
                                                    },
                                                ]}
                                            >

                                                <Input.Password
                                                    disabled
                                                    onBlur={this.handleConfirmBlur}
                                                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                />
                                            </Form.Item>}


                                        <FormItem className="gx-text-center">
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                loading={this.state.loading}
                                            >
                                                Submit
                                </Button>
                                            <Button
                                                type="reset"
                                                htmlType="reset"
                                                // loading={this.state.loading}
                                                onClick={this.resetPassword}
                                            >
                                                Cancel
                                 </Button>
                                        </FormItem>
                                    </Form>
                                </Spin>
                            </div>
                        </div>
                    </div>
                </div >
            </React.Fragment >
        );
    }
}

export default ConfirmEmail;