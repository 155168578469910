import React, { PureComponent } from 'react';


import SecondStep from "./SecondStep";
import '../Auth.css';

class ResetPassword extends PureComponent {
  state = {
    userDetails: {},
    activeStep: 0
  };

  handleNext = () => {
    this.setState(prevState => ({ activeStep: prevState.activeStep + 1 }));
  };

  render() {
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">

                {/* <img src={"https://via.placeholder.com/272x395"} alt='Neature' /> */}
              </div>
              <div className="gx-app-logo-wid">
                <h1>Change Password</h1>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")} />
              </div>
            </div>
            <div className="gx-app-login-content">
              <SecondStep />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
