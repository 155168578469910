import ApiHelper from "./ApiHelper";

class ProfileService {
  
  static async UpdateProfile(body) {
    return ApiHelper.putAuthenticated("edit-profile", body);
  }

  static async UpdateProfileStatus(body) {
    return ApiHelper.postAuthenticated("user/status", body);
  }

  static async GetProfileByID(email, body) {
    return ApiHelper.getAuthenticated(`user?email=${email}`, body)
  }

  static async GetUserByID(email, body) {
    return ApiHelper.getAuthenticated(`user?email=${email}`, body)
  }
  static async GetThemeByID(id) {
    return ApiHelper.getAuthenticated(`theme?user_id=${id}`)
  }
  static async UpdateTheme(body) {
    return ApiHelper.postAuthenticated("theme/add", body);
  }
  static async UploadProfileImage(body) {
    return ApiHelper.postAuthenticated("upload-profile", body);
  }
  static async GetProfileImage(body) {
    return ApiHelper.getAuthenticated("get-profile", body);
  }
  static async UpdateDefaultProfile(body) {
    return ApiHelper.postAuthenticated("upload-default-profile", body);
  }

  static async DeleteProfile(body) {
    return ApiHelper.postAuthenticated("delete-profile", body);
  }
 
  
}
export default ProfileService;
