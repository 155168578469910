import React, { PureComponent } from 'react';
import IntlMessages from "../../../../util/IntlMessages";
import { Form, Input } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './InputPrefix.css'

export default class InputPrefix extends PureComponent {


    render() {
        return (

            <React.Fragment>

                {this.props.email ? (
                    <Form.Item
                        label={this.props.label}
                        name={this.props.name}
                        rules={[{
                            type: 'email',
                            message: <IntlMessages id={this.props.validationMessage} />,
                        }, {
                            required: true,
                            message: <IntlMessages id={this.props.requiredMessage} />,
                        }]}
                    >
                        <Input autoComplete={false} className={'input-prefix-styles'} prefix={<UserOutlined />}
                            placeholder="email"
                            autoComplete="email" />
                    </Form.Item>
                )
                    :

                    (
                        <Form.Item
                            label={this.props.label}
                            name={this.props.name}
                            rules={[{
                                required: true,
                                message: this.props.requiredMessage,
                            }]}
                        >
                            <Input.Password className={'input-prefix-styles'} prefix={<LockOutlined />}
                                autoComplete={false}
                                type="password"
                                placeholder="Password"
                                autoComplete="current-password" />
                        </Form.Item>

                    )}

            </React.Fragment >



        )
    }
}
