/**
 * Status response
 */
export const INVALID_ID_PASSWORD_TEXT = 'invalid-password';

export const PASSWORD_EXPIRED_TEXT = 'password-expired';
export const INVALID_OTP = 'One Time Password(OTP) is not valid.';
export const SUCCESS = 'Success';
export const INVALID_TOKEN_TEXT = 'Invalid token';
export const INTERNAL_SERVER_ERROR_TEXT = 'Internal Server Error';
export const SERVER_STOP_TEXT = 'Server is not running';

export const SUCCESS_CODE = 200;
export const OTP_EXPIRED = 700;
export const SERVER_STOP_CODE = 100;
export const FAILURE_CODE = 100;
export const INVALID_AUTHENTICATION_CODE = 401;
export const INTERNAL_SERVER_ERROR_CODE = 500;
export const INVALID_TOKEN_CODE = 403;

/**
 * Role Configuration
 */
export const ADMINISTRATOR = 'SUPER_ADMIN';
export const COMPANYADMIN = 'COMPANY_ADMIN';
export const USER = 'USER';
export const PATIENT = 'PATIENT';
export const SECRETORY = 'SECRETORY';
export const DOCTOR = 'DOCTOR';
export const NURSE = 'NURSE';

export const ROUTE_BASE = 'schd';

/**
 * Common URLs (Role = PUBLIC)
 */
export const DASHBOARD = '/';
export const DASHBOARD_ROUTE = `/dashboard/crm`;
export const SIGNUP = `/signup`;
export const LOGIN = `/login`;
export const ACCESS_DENIED = `/access-denied`;
export const LOGOUT = `/logout`;
export const FORGOT_PASSWORD = `/forgotpassword`;
export const ACCEPT_INVITATION = `/invitation/checking`;

//otp text
export const IS_EMAIL = false;

/**
 * URLs
 */

export const SUPER_ADMIN_RESET_PASSWORD = `/main/admin/resetpassword`;

export const SUPER_ADMIN_SECRETORY_LIST = `/main/secretory`;
export const SUPER_ADMIN_ADD_SECRETORY = `/main/secretory/add`;
export const SUPER_ADMIN_EDIT_SECRETORY = `/main/secretory/edit`;
export const SUPER_ADMIN_LICENCE_LIST = `/main/licencedetails`;
export const SUPER_ADMIN_ADD_LICENCE = `/main/licencedetails/add`;
export const SUPER_ADMIN_EDIT_LICENCE = `/main/licencedetails/edit`;
export const SUPER_ADMIN_LICENCE_SUMMARY_LIST = `/main/licencesummary`;
export const SUPER_ADMIN_ADD_LICENCE_SUMMARY = `/main/licencesummary/add`;
export const SUPER_ADMIN_EDIT_LICENCE_SUMMARY = `/main/licencesummary/edit`;
export const SUPER_ADMIN_LICENCE_SUMMARY_LIST_VIEW = `/main/licencesummaryview`;
export const SUPER_ADMIN_USER_LIST = `/main/user`;
export const SUPER_ADMIN_USER_DETAILS = `/main/userdetails`;
export const SUPER_ADMIN_LEVEL_LIST = `/main/levelData`;
export const SUPER_ADMIN_LEVEL_DATA_ADD = `/main/levelData/add`;
export const SUPER_ADMIN_USER_DETAILS_ABSOLUTE = `/main/userdetails/absolute`;
export const SUPER_ADMIN_USERDETAIL_APPROACH_DATA = `/main/userdetails/approach`;
export const SUPER_ADMIN_SKILLDATA = `/main/score/skill`;
export const SUPER_ADMIN_ABSOLUTEDATA = `/main/score/absolute`;
export const SUPER_ADMIN_DIFFICULTYDATA = `/main/score/difficulty`;
export const SUPER_ADMIN_LORDATA = `/main/score/lor`;
export const SUPER_ADMIN_USER_DETAILS_VIEW_LEVEL = `/main/viewlevel`;
export const SUPER_ADMIN_USER_DETAILS_CHEPTER_WISE = `/main/chapterwise`;
export const SUPER_ADMIN_USER_DETAILS_VIEW_LEVEL_ABSOLUTE = `/main/viewlevel/absolute`;
export const SUPER_ADMIN_USER_DETAILS_CHEPTER_WISE_ABSOLUTE = `/main/chapterwise/absolute`;
export const SUPER_ADMIN_ADD_USER = `/main/user/add`;
export const SUPER_ADMIN_EDIT_USER = `/main/user/edit`;
export const SUPER_ADMIN_SCORE_LIST = `/main/score`;
export const SUPER_ADMIN_COMPANY_ADMIN_LIST = `/main/companyadmin`;
export const SUPER_ADMIN_COMPANY_DETAILS = `/main/companydetails`;
export const SUPER_ADMIN_ADD_COMPANY_ADMIN = `/main/companyadmin/add`;
export const SUPER_ADMIN_EDIT_COMPANY_ADMIN = `/main/companyadmin/edit`;
export const SUPER_ADMIN_ORGANIZATION_LIST = `/main/organization`;
export const SUPER_ADMIN_ADD_ORGANIZATION = `/main/organization/add`;
export const SUPER_ADMIN_COMPANY_LIST = `/main/company`;
export const SUPER_ADMIN_COMPANY_LIST2 = `main/company`;
export const SUPER_ADMIN_ADD_COMPANY = `/main/company/add`;
export const SUPER_ADMIN_EDIT_COMPANY = `/main/company/edit`;
export const SUPER_ADMIN_EDIT_ORGANIZATION = `/main/organization/edit`;
export const SUPER_ADMIN_DEPARTMENT_LIST = `/main/department`;
export const SUPER_ADMIN_ADD_DEPARTMENT = `/main/department/add`;
export const SUPER_ADMIN_EDIT_DEPARTMENT = `/main/department/edit`;
export const SUPER_ADMIN_BATCH_LIST = `/main/batch`;
export const SUPER_ADMIN_ADD_BATCH = `/main/batch/add`;
export const SUPER_ADMIN_EDIT_BATCH = `/main/batch/edit`;
export const SUPER_ADMIN_COUNTRY_LIST = `/main/country`;
export const SUPER_ADMIN_ADD_COUNTRY = `/main/country/add`;
export const SUPER_ADMIN_EDIT_COUNTRY = `/main/country/edit`;
export const SUPER_ADMIN_INDUSTRY_LIST = `/main/industry`;
export const SUPER_ADMIN_ADD_INDUSTRY = `/main/industry/add`;
export const SUPER_ADMIN_EDIT_INDUSTRY = `/main/industry/edit`;
export const SUPER_ADMIN_BASIC_INFO = `/main/profile/basic-info`;
export const SUPER_ADMIN_PRESCRIPTION = `/main/prescription/addprescription`;
export const SUPER_ADMIN_APPEARACE = `/main/appearace`;
export const SUPER_ADMIN_COUNTRY = `/main/settings/country`;
export const SUPER_ADMIN_COUNTRY_ADD = `/main/settings/country/add`;
export const SUPER_ADMIN_COUNTRY_EDIT = `/main/settings/country/edit`;
export const SUPER_ADMIN_STATE = `/main/settings/state`;
export const SUPER_ADMIN_STATE_ADD = `/main/settings/state/add`;
export const SUPER_ADMIN_STATE_EDIT = `/main/settings/state/edit`;
export const SUPER_ADMIN_CITY = `/main/settings/city`;
export const SUPER_ADMIN_CITY_ADD = `/main/settings/city/add`;
export const SUPER_ADMIN_CITY_EDIT = `/main/settings/city/edit`;
export const SUPER_ADMIN_THEMESETTING = `/main/settings/theme-setting`;
export const SUPER_ADMIN_REQUEST = `/main/settings/request`;
export const SUPER_ADMIN_PINPOLICY_LIST = `/main/pinpolicy/`;
export const SUPER_ADMIN_PINPOLICY_LIST_ADD = `/main/pinpolicy/add`;
export const SUPER_ADMIN_PINPOLICY_LIST_EDIT = `/main/pinpolicy/edit`;
export const SUPER_ADMIN_PASSWORDPLOCIY_LIST = `/main/passwordpolicy/`;
export const SUPER_ADMIN_PASSWORDPLOCIY_LIST_ADD = `/main/passwordpolicy/add`;
export const SUPER_ADMIN_PASSWORDPLOCIY_LIST_EDIT = `/main/passwordpolicy/edit`;
export const SUPER_ADMIN_EMAILTEMPLATES_LIST = `/main/emailtemplates/`;
export const SUPER_ADMIN_EMAILTEMPLATES_LIST_ADD = `/main/emailtemplates/add`;
export const SUPER_ADMIN_EMAIL_SERVICE = `/main/emailserver`;
export const SUPER_ADMIN_EMAIL_SERVICE_ADD = `/main/emailserver/add`;
export const SUPER_ADMIN_WEBRTC_CONFIG = `/main/webrtcconfig`;
export const SUPER_ADMIN_WEBRTC_CONFIG_ADD = `/main/webrtcconfig/add`;
export const SUPER_ADMIN_IVR_CONFIG = `/main/ivrconfig`;
export const SUPER_ADMIN_RECORDING_POLICY = `/main/recordingpolicy`;
export const SUPER_ADMIN_RECORDING_POLICY_ADD = `/main/recordingpolicy/add`;
export const SUPER_ADMIN_SURVEY = `/main/survey`;
export const SUPER_ADMIN_SURVEY_ADD = `/main/survey/add`;
export const SUPER_ADMIN_SURVEY_ANSWER = `/main/survey/answer`;

export const COMPANY_ADMIN_USER_DETAILS_ABSOLUTE = `/companyadmin/userdetails/absolute`;
export const COMPANY_ADMIN_SKILLDATA = `/companyadmin/score/skill`;
export const COMPANY_ADMIN_ABSOLUTEDATA = `/companyadmin/score/absolute`;
export const COMPANY_ADMIN_APPROACH_DATA = `/companyadmin/score/approach`;
export const COMPANY_ADMIN_USERDETAIL_APPROACH_DATA = `/companyadmin/userdetails/approach`;
export const COMPANY_ADMIN_DIFFICULTYDATA = `/companyadmin/score/difficulty`;
export const COMPANY_ADMIN_LORDATA = `/companyadmin/score/lor`;
export const COMPANY_ADMIN_USER_DETAILS = `/companyadmin/userdetails`;
export const COMPANY_ADMIN_USER_DETAILS_VIEW_LEVEL_ABSOLUTE = `/companyadmin/viewlevel/absolute`;
export const COMPANY_ADMIN_USER_DETAILS_CHEPTER_WISE_ABSOLUTE = `/companyadmin/chapterwise/absolute`;
export const COMPANY_ADMIN_USER_DETAILS_VIEW_LEVEL = `/companyadmin/viewlevel`;
export const COMPANY_ADMIN_USER_DETAILS_CHEPTER_WISE = `/companyadmin/chapterwise`;
export const COMPANY_ADMIN_SCORE_LIST = `/companyadmin/score`;
export const COMPANY_ADMIN_RESET_PASSWORD = `/companyadmin/admin/resetpassword`;
export const COMPANY_ADMIN_LICENCE_LIST = `/companyadmin/licencedetails`;
export const COMPANY_ADMIN_ADD_LICENCE = `/companyadmin/licencedetails/add`;
export const COMPANY_ADMIN_EDIT_LICENCE = `/companyadmin/licencedetails/edit`;
export const COMPANY_ADMIN_LICENCE_SUMMARY_LIST = `/companyadmin/licencesummary`;
export const COMPANY_ADMIN_ADD_LICENCE_SUMMARY = `/companyadmin/licencesummary/add`;
export const COMPANY_ADMIN_EDIT_LICENCE_SUMMARY = `/companyadmin/licencesummary/edit`;
export const COMPANY_ADMIN_LICENCE_SUMMARY_LIST_VIEW = `/companyadmin/licencesummaryview`;
export const COMPANY_ADMIN_USER_LIST = `/companyadmin/user`;
export const COMPANY_ADMIN_ADD_USER = `/companyadmin/user/add`;
export const COMPANY_ADMIN_EDIT_USER = `/companyadmin/user/edit`;
export const COMPANY_ADMIN_COMPANY_ADMIN_LIST = `/companyadmin/companyadmin`;
export const COMPANY_ADMIN_ADD_COMPANY_ADMIN = `/companyadmin/companyadmin/add`;
export const COMPANY_ADMIN_EDIT_COMPANY_ADMIN = `/companyadmin/companyadmin/edit`;
export const COMPANY_ADMIN_ORGANIZATION_LIST = `/companyadmin/organization`;
export const COMPANY_ADMIN_ADD_ORGANIZATION = `/companyadmin/organization/add`;
export const COMPANY_ADMIN_COMPANY_LIST = `/companyadmin/company`;
export const COMPANY_ADMIN_ADD_COMPANY = `/companyadmin/company/add`;
export const COMPANY_ADMIN_EDIT_COMPANY = `/companyadmin/company/edit`;
export const COMPANY_ADMIN_EDIT_ORGANIZATION = `/companyadmin/organization/edit`;
export const COMPANY_ADMIN_DEPARTMENT_LIST = `/companyadmin/department`;
export const COMPANY_ADMIN_ADD_DEPARTMENT = `/companyadmin/department/add`;
export const COMPANY_ADMIN_EDIT_DEPARTMENT = `/companyadmin/department/edit`;
export const COMPANY_ADMIN_BATCH_LIST = `/companyadmin/batch`;
export const COMPANY_ADMIN_ADD_BATCH = `/companyadmin/batch/add`;
export const COMPANY_ADMIN_EDIT_BATCH = `/companyadmin/batch/edit`;

export const USER_SCORE_LIST = `/user/score`;
export const USER_DETAILS = `/user/userdetails`;
export const USER_DETAILS_VIEW_LEVEL = `/user/viewlevel`;
export const USER_DETAILS_CHEPTER_WISE = `/user/chapterwise`;
export const USER_DETAILS_ABSOLUTE = `/user/userdetails/absolute`;
export const USER_DETAILS_VIEW_LEVEL_ABSOLUTE = `/user/viewlevel/absolute`;
export const USER_DETAILS_CHEPTER_WISE_ABSOLUTE = `/user/chapterwise/absolute`;
export const USER_RESET_PASSWORD = `/user/resetpassword`;

export const SECRETORY_DOCTOR_LIST = `/secretory/doctor`;
export const SECRETORY_ADD_DOCTOR = `/secretory/doctor/add`;
export const SECRETORY_EDIT_DOCTOR = `/secretory/doctor/edit`;
export const SECRETORY_DOCTOR_SCHEDULE_LIST = `/secretory/scheduledoctor`;
export const SECRETORY_ADD_DOCTOR_SCHEDULE = `/secretory/scheduledoctor/add`;
export const SECRETORY_EDIT_DOCTOR_SCHEDULE = `/secretory/doctorschedule/edit`;
export const SECRETORY_STAFF_WORK_HOURS_LIST = `/secretory/workhours`;
export const SECRETORY_STAFF_ADD_WORK_HOURS = `/secretory/workhours/add`;
export const SECRETORY_STAFF_SERVICE_SCHEDULE_LIST = `/secretory/sevice_schedulelist`;
export const SECRETORY_STAFF_ADD_SERVICE_SCHEDULE = `/secretory/sevice_schedulelist/add`;
export const SECRETORY_PATIENT_LIST = `/secretory/patient`;
export const SECRETORY_ADD_PATIENT = `/secretory/patient/add`;
export const SECRETORY_EDIT_PATIENT = `/secretory/patient/edit`;
export const SECRETORY_NURSE_LIST = `/secretory/nurse`;
export const SECRETORY_ADD_NURSE = `/secretory/nurse/add`;
export const SECRETORY_EDIT_NURSE = `/secretory/nurse/edit`;
export const SECRETORY_APPOINMENT_LIST = `/secretory/appoinment`;
export const SECRETORY_ADD_APPOINMENT = `/secretory/appoinment/add`;
export const SECRETORY_BASIC_INFO = `/secretory/profile/basic-info`;
export const SECRETORY_THEMESETTING = `/secretory/theme-setting`;
export const SECRETORY_TODO = `/secretory/todo`;
export const SECRETORY_TODO_EDIT = `/secretory/todo/edit`;
export const SECRETORY_TODO_LABEL = `/secretory/label`;
export const SECRETORY_ADD_LABEL = `/secretory/label/add`;
export const SECRETORY_REQUEST_STAFF = `/secretory/request`;
export const SECRETORY_REQUEST_STAFF_ADD = `/secretory/request/add`;
export const SECRETORY_DEPARTMENT_LIST = `/secretory/department`;
export const SECRETORY_ADD_DEPARTMENT = `/secretory/department/add`;
export const SECRETORY_EDIT_DEPARTMENT = `/secretory/department/edit`;
export const SECRETORY_SERVICE_LIST = `/secretory/services/`;
export const SECRETORY_ADD_SERVICE = `/secretory/services/add`;
export const SECRETORY_EDIT_SERVICE = `/secretory/services/edit`;
export const SECRETORY_EMAIL_SERVICE = `/secretory/emailserver`;
export const SECRETORY_CALENDER = `/secretory/mycalender`;
export const SECRETORY_PRESCRIPTION = `/secretory/prescription`;
export const SECRETORY_ADD_PRESCRIPTION = `/secretory/prescription/addprescription`;

export const DOCTOR_PATIENT_LIST = `/doctor/patient`;
export const DOCTOR_ADD_PATIENT = `/doctor/patient/add`;
export const DOCTOR_EDIT_PATIENT = `/doctor/patient/edit`;
export const DOCTOR_APPOINMENT_LIST = `/doctor/appoinment`;
export const DOCTOR_ARCHIVE_LIST = `/doctor/archive`;
export const DOCTOR_ADD_APPOINMENT = `/doctor/appoinment/add`;
export const DOCTOR_DOCTOR_SCHEDULE = `/doctor/scheduledoctor`;
export const DOCTOR_ADD_DOCTOR_SCHEDULE = `/doctor/scheduledoctor/add`;
export const DOCTOR_BASIC_INFO = `/doctor/profile/basic-info`;
export const DOCTOR_THEMESETTING = `/doctor/theme-setting`;
export const DOCTOR_TODO = `/doctor/todo`;
export const DOCTOR_TODO_EDIT = `/doctor/todo/edit`;
export const DOCTOR_TODO_LABEL = `/doctor/label`;
export const DOCTOR_ADD_LABEL = `/doctor/label/add`;
export const DOCTOR_CALENDER = `/doctor/mycalender`;
export const DOCTOR_WAITING_ROOM = `/doctor/waitingroom`;
export const DOCTOR_MEET_NOW = `/doctor/meetrightnow`;

export const NURSE_PATIENT_LIST = `/nurse/patient`;
export const NURSE_ADD_PATIENT = `/nurse/patient/add`;
export const NURSE_EDIT_PATIENT = `/nurse/patient/edit`;
export const NURSE_APPOINMENT_LIST = `/nurse/appoinment`;
export const NURSE_ADD_APPOINMENT = `/nurse/appoinment/add`;
export const NURSE_BASIC_INFO = `/nurse/profile/basic-info`;
export const NURSE_THEMESETTING = `/nurse/theme-setting`;
export const NURSE_TODO = `/nurse/todo`;
export const NURSE_TODO_EDIT = `/nurse/todo/edit`;
export const NURSE_TODO_LABEL = `/nurse/label`;
export const NURSE_ADD_LABEL = `/nurse/label/add`;

export const PATIENT_CALENDER = `/patient/mycalender`;
export const PATIENT_APPOINMENT = `/patient/appoinment`;
export const PATIENT_APPOINMENT_HISTORY = `/patient/appointment/history`;
export const PATIENT_ADD_APPOINMENT = `/patient/appoinment/add`;
export const PATIENT_BASIC_INFO = `/patient/profile/basic-info`;
export const PATIENT_THEMESETTING = `/patient/theme-setting`;

export const VIDEO_STREAMING = `streamvideo`;
