import React, { PureComponent } from 'react';
import '../Auth.css';
// import loginLogo from '../../../../assets/images/logo/logo.png';
import { Button, Form, message } from "antd";
import { Link, withRouter } from 'react-router-dom';
import AuthService from '../../../../service/AuthService';
import * as urlConfig from '../../../../constants/URLConstant';
import InputPrefix from '../../InputControl/InputPerefix/InputPrefix';
// import AppearanceService from '../../../../service/AppearanceService';
import Session from 'store2';
import IntlMessages from "util/IntlMessages";

const FormItem = Form.Item;


class ResetPassword extends PureComponent {
  state = {

    loadingData: false,
    email: '',
    submitted: false,
    logo: null
  };

  onFinish = values => {
    if (values) {
      values.email = values.email.toLowerCase()
      this.checkEmail(values);
    }
  };

  checkEmail = async (formData) => {
    this.setState({ loadingData: true });
    const response = await AuthService.VerifyEmail(formData);
    if (response.status === urlConfig.SUCCESS_CODE) {
      message.success(response.message);
      this.props.history.replace(`/login`)
      this.setState({ submitted: true, loadingData: false });
    } else {
      message.error(response.message)
      this.setState({ submitted: true, loadingData: false });
    }

  }

  render() {
    const { loadingData } = this.state;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">

                {/* <img src={"https://via.placeholder.com/272x395"} alt='Neature' /> */}
              </div>
              <div className="gx-app-logo-wid">
                <h1>New Password</h1>
                {/* <p><IntlMessages id="app.userAuth.bySigning" /></p> */}
                {/* <p><IntlMessages id="app.userAuth.getAccount" /></p> */}
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")} />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form layout="vertical" onFinish={this.onFinish} className="gx-login-form gx-form-row0">

                <InputPrefix
                  label='Email'
                  name="email"
                  autoComplete={false}
                  validationMessage='enter.valid.email'
                  requiredMessage='input.email'
                  email={true}
                />
                <Form.Item className="gx-text-center">
                  <Button type="primary" className="gx-mb-0 gx-text-center" htmlType="submit" loading={this.state.loading}>
                    Submit
                  </Button>
                  <Button
                    className="gx-mb-0 gx-text-center"
                    htmlType="button"
                    disabled={loadingData}
                    onClick={() => this.props.history.replace(`/login`)}
                  >
                    Cancel
              </Button>

                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default withRouter(ResetPassword);
