import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";

const Main = ({match}) => (
  <Switch>
    <Route path={`${match.url}/licencedetails`} exact component={asyncComponent(() => import('./Licence'))}/>
    <Route path={`${match.url}/licencedetails/add`} component={asyncComponent(() => import('./Licence/AddLicence'))}/>
    <Route path={`${match.url}/licencedetails/edit`} component={asyncComponent(() => import('./Licence/AddLicence'))}/>

    <Route path={`${match.url}/licencesummary`} exact component={asyncComponent(() => import('./LicenseSummary'))}/>
    <Route path={`${match.url}/licencesummary/add`} component={asyncComponent(() => import('./LicenseSummary/AddLicence'))}/>
    <Route path={`${match.url}/licencesummary/edit`} component={asyncComponent(() => import('./LicenseSummary/AddLicence'))}/>

    <Route path={`${match.url}/licencesummaryview`} exact component={asyncComponent(() => import('./LicenseSummaryView'))}/>

    <Route path={`${match.url}/company`} exact component={asyncComponent(() => import('./company'))}/>
    <Route path={`${match.url}/company/add`} component={asyncComponent(() => import('./company/AddCompany'))}/>
    <Route path={`${match.url}/company/edit`} component={asyncComponent(() => import('./company/AddCompany'))}/>

    <Route path={`${match.url}/batch`} exact component={asyncComponent(() => import('./batch'))}/>
    <Route path={`${match.url}/batch/add`} component={asyncComponent(() => import('./batch/AddBatch'))}/>
    <Route path={`${match.url}/batch/edit`} component={asyncComponent(() => import('./batch/AddBatch'))}/>

    <Route path={`${match.url}/companyadmin`} exact component={asyncComponent(() => import('./companyadmin'))}/>
    <Route path={`${match.url}/companyadmin/add`} component={asyncComponent(() => import('./companyadmin/AddCompanyAdmin'))}/>
    <Route path={`${match.url}/companyadmin/edit`} component={asyncComponent(() => import('./companyadmin/AddCompanyAdmin'))}/>

    <Route path={`${match.url}/score`} exact component={asyncComponent(() => import('./score'))}/>
    <Route path={`${match.url}/score/absolute`} exact component={asyncComponent(() => import('./score/absoluteData'))} />
    <Route path={`${match.url}/score/approach`} exact component={asyncComponent(() => import('./score/approach'))} />
    <Route path={`${match.url}/score/skill`} exact component={asyncComponent(() => import('./score/skillData'))} />
    <Route path={`${match.url}/score/difficulty`} exact component={asyncComponent(() => import('./score/difficultyData'))} />
    <Route path={`${match.url}/score/lor`} exact component={asyncComponent(() => import('./score/lorData'))} />
    <Route path={`${match.url}/userdetails`} exact component={asyncComponent(() => import('./ScoreView/UserDetails'))}/>
    <Route path={`${match.url}/viewlevel`} exact component={asyncComponent(() => import('./ScoreView/ViewLevel'))}/>
    <Route path={`${match.url}/userdetails/approach`} exact component={asyncComponent(() => import('./ScoreView/Approach'))} />
    <Route path={`${match.url}/chapterwise`} exact component={asyncComponent(() => import('./ScoreView/ChepterWise'))}/>
    <Route path={`${match.url}/userdetails/absolute`} exact component={asyncComponent(() => import('./scoreViewAbsolute/UserDetails'))}/>
    <Route path={`${match.url}/viewlevel/absolute`} exact component={asyncComponent(() => import('./scoreViewAbsolute/ViewLevel'))}/>
    <Route path={`${match.url}/chapterwise/absolute`} exact component={asyncComponent(() => import('./scoreViewAbsolute/ChepterWise'))}/>

    <Route path={`${match.url}/user`} exact component={asyncComponent(() => import('./user'))}/>
    <Route path={`${match.url}/user/add`} component={asyncComponent(() => import('./user/AddUser'))}/>
    <Route path={`${match.url}/user/edit`} component={asyncComponent(() => import('./user/EditUser'))}/>

    <Route path={`${match.url}/admin/resetpassword`} component={asyncComponent(() => import('./ResetAdminPassword'))}/>

  </Switch>
);

export default Main;
