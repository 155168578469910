import ApiHelper from "./ApiHelper";
import jwt from 'jsonwebtoken';
import Session from 'store2';
import * as apiURL from './Apiconfig';

class AuthService {
  static async Login(body) {
    return ApiHelper.postAnonymous("auth/join", body);
  }

  static async ValidateToken(body) {
    return ApiHelper.getAuthenticated("validate-token", body);
  }


  static async GameLogin(data) {
    return ApiHelper.postAuthenticated("game/auth/join", data);
  }

  static async VerifyOTP(body) {
    return ApiHelper.postAnonymousOTP("verify-otp", body);
  }

  static async VerifyEmail(body) {
    return ApiHelper.putAuthenticated("forgotpassword-sendemail", body);
  }

  static async ResetPassword(body) {
    return ApiHelper.putAuthenticated("forgotpassword", body);
  }

  static async NewPassword(body) {
    return ApiHelper.putAuthenticated("new-password", body);
  }

  static async PasswordCheck(body) {
    return ApiHelper.putAuthenticated("password-check", body);
  }

  static async ConfirmEmail(body) {
    return ApiHelper.putAuthenticated("confirm-email", body);
  }

  static GetCurrentLoggedUserDetails() {
    const userAccessToken = Session.session('userAccessToken');
    if (userAccessToken) {

      return jwt.decode(userAccessToken.replace('Bearer ', ''));
    }
    return null;
  }

  static async saveEulaStatus(body) {
    return ApiHelper.postAuthenticated("eula/accept", body);
  }

  static async getEulaStatus(body) {
    return ApiHelper.postAnonymous("eula/get", body);
  }
}
export default AuthService;
